import { useCallback, useEffect, useState } from 'react';

import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';

import {
  getBanksAction,
  getContractTypesAction,
  getPaymentMethodsAction,
  getPaymentPeriodAction,
  getRetiresTypesAction,
  getWorkerTypesAction,
} from '../actions/configAction';
import {
  addExpenseAction,
  addIncomeAction,
  getEmployees,
  loadingCreateEmployeAction,
  resetFormEmployee,
  saveBasicDataAction,
  saveContractedDataAction,
  saveNoveltiesDataAction,
  savePaymentDataAction,
  saveResponseSaveProcess,
  setReintegreStatusAction,
  updateExpenseAction,
  updateIncomeAction,
} from '../actions/employeeActions';
import { SuccessAlert2 } from '../helpers/alert.helpers';
import EmployeesService from '../services/employeesServices';
import { showErrorMessage } from '../utils/showErrorService';
import { useEmployees } from './useEmployees';
import { useMyAccount } from './useMyAccount';
import { usePayroll } from './usePayroll';

export const useRegisterEmployee = () => {
  const [loadingRegister, setLoadingRegister] = useState(false);
  const [formCompleted, setFormCompleted] = useState({
    basicData: false,
    contractData: false,
    paymentData: false,
    noveltiesData: false,
  });
  const { isEditing, employeeDetail, fillEmployeeById } = useEmployees();
  const { sendSupportToMail } = usePayroll();
  const { getBranchesByEmpresaId } = useMyAccount();

  const [paymentData, setPaymentData] = useState({
    medioPago: '',
    entidadFinanciera: '',
    toogleTipoCuenta: '1',
    numeroCuenta: '',
    descripcion: '',
    favorito: false,
  });
  const [noveltiesData, setNoveltiesData] = useState([]);
  const [contractData, setContractData] = useState({
    tipoTrabajador: '',
    subTipoTrabajador: '',
    tipoContrato: '',
    numeroContrato: '',
    fechaIngreso: '',
    cargo: '',
    salario: 0,
    fechaSalario: '',
    dianPeriodoNomina: '',
    esSalarioIntegral: false,
    esAltoRiesgo: false,
    sucursal: '',
    departamento: '',
    municipio: '',
    direccion: '',
    fechaRetiro: '',
    fechaFinContrato: '',
    causalretiroId: '',
    causalretiro: null,
  });
  const [basicData, setBasicData] = useState({
    id: 0,
    favorito: false,
    diantipodocumentoidentidadid: '',
    documento: '',
    primerApellido: '',
    segundoApellido: '',
    primerNombre: '',
    otroNombre: '',
    correoelectronico: '',
    telefono: '',
    departamento: '',
    municipio: '',
    direccion: '',
  });

  // Observables de la store (redux)
  const registerReduxForm = useSelector(
    (state) => state.employeeReducer.registerEmployeeObject || null,
  );
  const responseCreateProcess = useSelector(
    (state) =>
      state.employeeReducer.registerEmployeeObject.responseCreateProcess ||
      null,
  );
  const getWorkerTypes = useSelector(
    (state) => state.configReducer.getWorkerTypes || null,
  );
  const getWorkerSubTypes = useSelector(
    (state) => state.configReducer.getWorkerSubTypes || null,
  );
  const getContractTypes = useSelector(
    (state) => state.configReducer.getContractTypesResponse || null,
  );
  const getPaymentPeriod = useSelector(
    (state) => state.configReducer.getPaymentPeriodResponse || null,
  );
  const getPaymentMethods = useSelector(
    (state) => state.configReducer.getPaymentMethodsResponse || null,
  );
  const getBanks = useSelector(
    (state) => state.configReducer.getBanksResponse || null,
  );
  const statesList = useSelector(
    (state) => state.configReducer.getStatesResponse || [],
  );
  const citiesList = useSelector(
    (state) => state.configReducer.getAllCitiesResponse || [],
  );
  const getListBranch = useSelector(
    (state) =>
      state.branchOfficeReducer.filteredBranchOfficesList?.data || null,
  );
  const getRetireCauses = useSelector(
    (state) => state.configReducer.getRetiresTypes || null,
  );

  const { reintegreStatus } = useSelector((s) => s.employeeReducer);
  // dispatch hook
  const dispatch = useDispatch();

  // PRIVATE

  function resetForm() {
    setBasicData((prevState) => {
      return {
        ...prevState,
        favorito: false,
        primerApellido: '',
        segundoApellido: '',
        primerNombre: '',
        otroNombre: '',
        correoelectronico: '',
        telefono: '',
        municipio: '',
        departamento: '',
        direccion: '',
      };
    });
    setContractData({
      tipoTrabajador: '',
      subTipoTrabajador: '',
      tipoContrato: '',
      numeroContrato: '',
      fechaIngreso: '',
      fechaRetiro: '',
      fechaFinContrato: '',
      cargo: '',
      salario: 0,
      dianPeriodoNomina: '',
      esSalarioIntegral: false,
      esAltoRiesgo: false,
      sucursal: '',
      departamento: '',
      municipio: '',
      direccion: '',
      causalretiro: null,
    });
    setPaymentData({
      diantipodocumentoidentidadid: 0,
      documento: '',
      medioPago: '',
      entidadFinanciera: '',
      toogleTipoCuenta: '1',
      numeroCuenta: '',
      descripcion: '',
    });
    setNoveltiesData([]);
  }

  const fillFormState = (pReintegreStatus) => {
    if (isEditing && employeeDetail) {
      fillDataBasic(employeeDetail);
      fillDataContract(employeeDetail, pReintegreStatus);
      fillDataPayment(employeeDetail);
      fillNoveltiesData(employeeDetail);
    } else if (!isEditing) {
      if (registerReduxForm) {
        if (registerReduxForm.formData?.basicData) {
          fillDataBasic(registerReduxForm.formData.basicData);
        }
        if (registerReduxForm.formData?.contractData) {
          fillDataContract(
            registerReduxForm.formData.contractData,
            pReintegreStatus,
          );
        }
        if (registerReduxForm.formData?.paymentData) {
          fillDataPayment(registerReduxForm.formData.paymentData);
        }
        if (registerReduxForm.formData?.noveltiesData) {
          fillNoveltiesData(registerReduxForm.formData.noveltiesData);
        }
      }
    }
  };

  const fillDataBasic = (employeeDetail) => {
    if (isEditing) {
      let city = citiesList?.find(
        (c) => c.id.toString() === employeeDetail?.ciudadid.toString(),
      );

      let state = statesList?.find(
        (s) => Number(s.value) === Number(city?.departamentoid),
      );

      const data = {
        id: employeeDetail.id || null,
        diantipodocumentoidentidadid:
          employeeDetail?.diantipodocumentoidentidadid,
        documento: employeeDetail?.documento,
        primerApellido: employeeDetail?.primerapellido,
        segundoApellido: employeeDetail?.segundoapellido || null,
        primerNombre: employeeDetail?.primernombre,
        otroNombre: employeeDetail?.otronombre,
        correoelectronico: employeeDetail?.correoelectronico,
        telefono: employeeDetail?.telefono,
        departamento: state || null,
        municipio: { value: city?.id ?? 0, text: city?.descripcion ?? '' },
        direccion: employeeDetail?.direccion,
        favorito: employeeDetail?.favorito,
      };

      saveBasicData(data);
      setBasicData(data);
    }
  };

  /**
   * Llenar información de contrato
   * @param {*} employeeDetail
   */
  const fillDataContract = (employeeDetail, pReintegreStatus) => {
    if (isEditing) {
      let city = citiesList?.find(
        (c) => c.id.toString() === employeeDetail?.trabajociudadid.toString(),
      );

      let state = statesList?.find(
        (s) => Number(s.value) === Number(city.departamentoid),
      );

      let data = {
        EmpleadoId: employeeDetail?.id,
        tipoTrabajador: employeeDetail?.tipotrabajador?.id,
        subTipoTrabajador: employeeDetail?.subtipotrabajador?.id,
        tipoContrato: employeeDetail?.tipocontratoid,
        numeroContrato: employeeDetail?.numerocontrato,
        fechaIngreso: employeeDetail?.fechaingreso,
        fechaRetiro: employeeDetail?.fecharetiro || '',
        fechaFinContrato: employeeDetail?.fechafinalizacioncontrato || '',
        cargo: employeeDetail?.cargo,
        salario: employeeDetail?.salario,
        dianPeriodoNomina: employeeDetail?.dianperiodonominaid,
        esSalarioIntegral: employeeDetail?.salariointegral,
        esAltoRiesgo: employeeDetail?.altoriesgo,
        sucursal: employeeDetail?.sucursalid,
        departamento: state || null,
        municipio: { value: city.id, text: city.descripcion },
        direccion: employeeDetail?.trabajodireccion,
        causalretiroid: employeeDetail?.causalretiroid ?? 0,
        causalretiro: employeeDetail?.causalretiro ?? null,
      };

      //Limpiar campos cuando es un reintegro
      if (pReintegreStatus) {
        data = {
          ...data,
          numeroContrato: '',
          fechaIngreso: '',
          fechaRetiro: null,
          fechaFinContrato: '',
          salario: '',
          causalretiroid: null,
          causalretiro: null,
        };
      }
      setContractData(data);
      saveContractData(data);
    }
  };

  const fillNoveltiesData = (employeeDetail) => {
    if (isEditing) {
      const ingresos = employeeDetail.ingresos.map((data) => {
        return {
          id: data.id,
          empleadoid: data.empleadoid,
          conceptoid: data.conceptoid,
          valor: data.valor,
          cantidad: data.cantidad,
          porcentaje: data.porcentaje,
          idstate: data.idstate,
          concepto: data.concepto.descripcion,
          descripcion: data.descripcion,
          ingreso: true,
        };
      });

      const deducciones = employeeDetail.deducciones.map((data) => {
        return {
          id: data.id,
          empleadoid: data.empleadoid,
          conceptoid: data.conceptoid,
          valor: data.valor,
          cantidad: data.cantidad,
          concepto: data.concepto.descripcion,
          descripcion: data.descripcion,
          porcentaje: data.porcentaje,
          idstate: data.idstate,
          ingreso: false,
        };
      });

      setNoveltiesData([...ingresos, ...deducciones]);
      saveNoveltiesData([...ingresos, ...deducciones]);
    }
  };

  const fillDataPayment = (employeeDetail) => {
    if (isEditing) {
      let bank = null;
      if (employeeDetail.entidadbancariaid) {
        bank = getBanks?.find(
          (c) =>
            c.value.toString() === employeeDetail?.entidadbancariaid.toString(),
        );
      }

      let paymentMethod = null;
      if (employeeDetail.dianmediopagoid) {
        paymentMethod = getPaymentMethods?.find(
          (c) =>
            c.value.toString() === employeeDetail?.dianmediopagoid.toString(),
        );
      }

      const data = {
        diantipodocumentoidentidadid:
          employeeDetail?.diantipodocumentoidentidadid,
        medioPago: paymentMethod,
        entidadFinanciera: bank,
        toogleTipoCuenta: employeeDetail?.tipocuentaid?.toString() || '',
        numeroCuenta: employeeDetail?.numerocuenta,
        descripcion: employeeDetail?.descripcionotros,
      };

      setPaymentData(data);
      savePaymentData(data);
    }
  };

  //METHODS

  /**
   * Guarda los datos basicos para la creacion de empleado en REDUX
   * @param {obj} basicData Obj a guardar
   */
  const saveBasicData = useCallback((basicData) => {
    dispatch(saveBasicDataAction(basicData));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Guarda los datos de contratacion para la creacion de empleado en REDUX
   * @param {obj} contractData Obj a guardar
   */
  const saveContractData = useCallback((contractData) => {
    dispatch(saveContractedDataAction(contractData));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Guarda los datos de novedades fijas creacion de empleado en REDUX
   * @param {obj} noveltiesData Obj a guardar
   */
  const saveNoveltiesData = useCallback((noveltiesData) => {
    dispatch(saveNoveltiesDataAction(noveltiesData));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Guarda los datos de forma de pago para la creacion de empleado en REDUX
   * @param {obj} paymentData Obj a guardar
   */
  const savePaymentData = useCallback((paymentData) => {
    dispatch(savePaymentDataAction(paymentData));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Reset formulario de empleado en REDUX
   */
  const resetFormRedux = useCallback(() => {
    dispatch(resetFormEmployee());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Prepara y guarda en DB el formulario, añadiendo el progress que al finalizar la promesa ejecuta
   * la función por parámetro completeProcess false.
   */
  const createEmployeeProcess = (completeProcess) => {
    dispatch(loadingCreateEmployeAction(true));
    const payload = fillPayloadToCreate();
    if (payload) {
      EmployeesService.createEmployeeService(payload)
        .then((results) => {
          saveResponseCreate(results.data);
          const message = `Se ha creado el nuevo empleado, de manera exitosa.`;
          SuccessAlert2('Felicidades!!!', message);

          dispatch(getEmployees());
        })
        .catch((error) => {
          showErrorMessage(error, 'Error al crear el empleado.');
        })
        .finally(() => {
          dispatch(loadingCreateEmployeAction(false));
          completeProcess(false);
        });
    }
  };

  const saveResponseCreate = (data) => {
    dispatch(saveResponseSaveProcess(data));
  };

  /**
   * Prepara y guarda en DB el formulario, añadiendo el progress que al finalizar la promesa ejecuta
   * la función por parámetro completeProcess true.
   */
  function updateEmployeeProcess(pEsreintegro, completeProcess) {
    dispatch(loadingCreateEmployeAction(true));
    const payload = fillPayloadToUpdate(pEsreintegro);

    if (payload && payload.id !== 0) {
      EmployeesService.updateEmployeeService(payload)
        .then((results) => {
          const message = pEsreintegro
            ? `Reintegraste el empleado ${employeeDetail.primernombre} ${employeeDetail.primerapellido} de manera exitosa.`
            : `Actualizaste el empleado ${employeeDetail.primernombre} ${employeeDetail.primerapellido} de manera exitosa.`;
          SuccessAlert2('Buen trabajo!!!', message);

          dispatch(getEmployees());
          fillEmployeeById(results.data.result.id);
        })
        .catch((error) => {
          showErrorMessage(
            error,
            'Error al actualizar los datos del empleado.',
          );
        })
        .finally(() => {
          dispatch(loadingCreateEmployeAction(false));
          completeProcess(true);
        });
    }
  }

  const fillPayloadToCreate = () => {
    const payloadNovelties = noveltiesData.map((data) => {
      return {
        Id: typeof data.id === 'string' ? 0 : data.id,
        Cantidad: data.cantidad,
        Conceptoid: data.conceptoid,
        Porcentaje: data.porcentaje,
        Valor: data.valor,
        idstate: data.idstate,
        Descripcion: data.descripcion,
        CreatedOn: format(new Date(), 'yyyy-MM-dd'),
        Modifiedon: format(new Date(), 'yyyy-MM-dd'),
      };
    });

    const data = {
      Favorito: basicData.favorito,
      Diantipodocumentoidentidadid: Number(
        registerReduxForm.formData.basicData.diantipodocumentoidentidadid,
      ), //Obligatorio // Si
      Documento: registerReduxForm.formData.basicData.documento, // Obligatorio // Si
      Primerapellido: registerReduxForm.formData.basicData.primerApellido, // Obligatorio // Si
      Segundoapellido:
        registerReduxForm.formData.basicData.segundoApellido || null, // ObligatorioObligaotrio // Si
      Primernombre: registerReduxForm.formData.basicData.primerNombre, // Obligatorio // Si
      Otronombre: registerReduxForm.formData.basicData.otroNombre || null, // Opcional // Si
      Correoelectronico: registerReduxForm.formData.basicData.correoelectronico, // Opcional // Si
      Telefono: registerReduxForm.formData.basicData.telefono, //Opcionaal // Si
      Ciudadid: Number(registerReduxForm.formData.basicData.municipio.value), //Obligatorio // Si
      Direccion: registerReduxForm.formData.basicData.direccion, //Obligatorio //             //Campos obligatorios en BD
      Empresaid: JSON.parse(localStorage.getItem('dataCompany')).companyId,
      Tipotrabajadorid: Number(
        registerReduxForm.formData.contractData.tipoTrabajador,
      ), //Obligatorio // Si
      Subtipotrabajadorid: Number(
        registerReduxForm.formData.contractData.subTipoTrabajador,
      ), //Obligatorio // Si
      Tipocontratoid: Number(
        registerReduxForm.formData.contractData.tipoContrato,
      ), //Obligatorio // Si
      Cargo: registerReduxForm.formData.contractData.cargo, //Obligatorio // Si
      Fechaingreso: registerReduxForm.formData.contractData.fechaIngreso, //Obligatorio // Si
      Fechafinalizacioncontrato:
        registerReduxForm.formData.contractData.fechaFinContrato,
      Salario: Number(registerReduxForm.formData.contractData.salario), //Obligatorio // Si
      Trabajociudadid: Number(
        registerReduxForm.formData.contractData.municipio.value,
      ), //Obligatorio // Si
      Trabajodireccion: registerReduxForm.formData.contractData.direccion, //Obligatorio // Si
      Dianperiodonominaid: Number(
        registerReduxForm.formData.contractData.dianPeriodoNomina,
      ), // Opcional -----periodoNominaId, // Si
      Numerocontrato: registerReduxForm.formData.contractData.numeroContrato, //Opcional, // No esta
      Altoriesgo: registerReduxForm.formData.contractData.esAltoRiesgo, // Obligatorio // No esta
      Sucursalid: Number(registerReduxForm.formData.contractData.sucursal), //Obligatorio / No esta , numero o null
      Salariointegral:
        registerReduxForm.formData.contractData.esSalarioIntegral, //Obligatorio // No esta
      Dianmediopagoid: Number(
        registerReduxForm.formData.paymentData.medioPago.value,
      ), //Obligatorio , medioPagoId, // Si
      Entidadbancariaid:
        registerReduxForm.formData.paymentData.entidadFinanciera.value || null, //Opcional - NULL
      Tipocuentaid: Number(
        registerReduxForm.formData.paymentData.toogleTipoCuenta,
      ), //Opcional -null
      Numerocuenta: registerReduxForm.formData.paymentData.numeroCuenta, //Opcional
      Descripcionotros: registerReduxForm.formData.paymentData.descripcion, //Opcional
      Causalretiroid: '', // //Opcional --nuLL
      Opmovimientofijo: [...payloadNovelties], // Vacio o null o no enviar campo
    };
    return data;
  };

  const fillPayloadToUpdate = (pEsreintegro = false) => {
    let payloadNovelties = [];

    noveltiesData.forEach((data) => {
      if (typeof data.id === 'string' && data.idstate !== 1) {
        payloadNovelties.push({
          Id: 0,
          Cantidad: data.cantidad,
          Descripcion: data.descripcion || null,
          Conceptoid: data.conceptoid,
          Porcentaje: data.porcentaje || null,
          Valor: data.valor,
          idstate: data.idstate,
          CreatedOn: format(new Date(), 'yyyy-MM-dd'),
          Modifiedon: format(new Date(), 'yyyy-MM-dd'),
        });
      } else if (typeof data.id !== 'string') {
        payloadNovelties.push({
          Id: data.id,
          Cantidad: data.cantidad,
          Descripcion: data.descripcion || null,
          Conceptoid: data.conceptoid,
          Porcentaje: data.porcentaje || null,
          Valor: data.valor,
          idstate: data.idstate || 0,
          CreatedOn: format(new Date(), 'yyyy-MM-dd'),
          Modifiedon: format(new Date(), 'yyyy-MM-dd'),
        });
      }
      return payloadNovelties;
    });

    const nuevaFehaRetiro = !!contractData.fechaRetiro
      ? format(new Date(contractData.fechaRetiro), 'yyyy-MM-dd')
      : null;

    const data = {
      Id: basicData.id,
      Favorito: basicData.favorito,
      Diantipodocumentoidentidadid: basicData.diantipodocumentoidentidadid, //Obligatorio // Si
      Documento: basicData.documento, // Obligatorio // Si
      Primerapellido: basicData.primerApellido, // Obligatorio // Si
      Segundoapellido: basicData.segundoApellido || null, // ObligatorioObligaotrio // Si
      Primernombre: basicData.primerNombre, // Obligatorio // Si
      Otronombre: basicData.otroNombre, // Opcional // Si
      Correoelectronico: basicData.correoelectronico, // Opcional // Si
      Telefono: basicData.telefono, //Opcionaal // Si
      Ciudadid: basicData.municipio.value, //Obligatorio // Si
      Direccion: basicData.direccion, //Obligatorio //             //Campos obligatorios en BD
      Empresaid: JSON.parse(localStorage.getItem('dataCompany')).companyId,
      Tipotrabajadorid: Number(contractData.tipoTrabajador), //Obligatorio // Si
      Subtipotrabajadorid: Number(contractData.subTipoTrabajador), //Obligatorio // Si
      Tipocontratoid: Number(contractData.tipoContrato), //Obligatorio // Si
      Cargo: contractData.cargo, //Obligatorio // Si
      Fechaingreso: contractData.fechaIngreso, //Obligatorio // Si
      Fechafinalizacioncontrato: contractData.fechaFinContrato || '',
      Salario: contractData.salario, //Obligatorio // Si
      Fechasalario: contractData.fechaSalario, //Opcional
      Trabajociudadid: contractData.municipio.value, //Obligatorio // Si
      Trabajodireccion: contractData.direccion, //Obligatorio // Si
      Dianperiodonominaid: contractData.dianPeriodoNomina, // Opcional -----periodoNominaId, // Si
      Numerocontrato: contractData.numeroContrato, //Opcional, // No esta
      Altoriesgo: contractData.esAltoRiesgo, // Obligatorio // No esta
      Sucursalid: contractData.sucursal, //Obligatorio / No esta , numero o null
      Salariointegral: contractData.esSalarioIntegral, //Obligatorio // No esta
      Fecharetiro: nuevaFehaRetiro, //Opcional -null
      Dianmediopagoid: paymentData.medioPago.value, //Obligatorio , medioPagoId, // Si
      Entidadbancariaid: paymentData.entidadFinanciera?.value || null, //Opcional - NULL
      Tipocuentaid: Number(paymentData.toogleTipoCuenta), //Opcional -null
      Numerocuenta: paymentData.numeroCuenta, //Opcional
      Descripcionotros: paymentData.descripcion, //Opcional
      Causalretiroid: !!contractData.causalretiroid
        ? Number(contractData.causalretiroid)
        : null, // //Opcional --nuLL
      Idstate: 0,
      Opmovimientofijo:
        payloadNovelties && payloadNovelties.length
          ? [...payloadNovelties]
          : null, // Vacio o null o no enviar campo
      Esreintegro: pEsreintegro,
    };

    return data;
  };
  // REACT HOOKS
  // Reactivo a hook employees, contiene estado de edicion y empleado en la store

  useEffect(() => {
    setLoadingRegister(true);

    if (!isEditing) {
      saveBasicData(null);
      saveContractData(null);
      savePaymentData(null);
      resetForm();
    }

    // llena estados del hook con reddux depende si esta en edicion o no
    fillFormState(reintegreStatus);

    if (!getWorkerTypes) {
      dispatch(getWorkerTypesAction());
    }
    if (!getContractTypes) {
      dispatch(getContractTypesAction());
    }
    if (!getPaymentPeriod) {
      dispatch(getPaymentPeriodAction());
    }
    if (!getPaymentMethods) {
      dispatch(getPaymentMethodsAction());
    }
    if (!getBanks) {
      dispatch(getBanksAction());
    }
    if (!getRetireCauses) {
      dispatch(getRetiresTypesAction());
    }
    if (!getListBranch) {
      getBranchesByEmpresaId('', false);
    }

    setLoadingRegister(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing, reintegreStatus]);

  // INCOMES EXPENSES ACTIONS

  /**
   * guarda en el estado income
   * @param {obj} objPayroll payroll income
   */
  const addIncome = useCallback(
    (objPayroll) => {
      objPayroll.ingreso = true;

      setNoveltiesData((prevState) => {
        return [...prevState, objPayroll];
      });

      dispatch(addIncomeAction(objPayroll));
    },
    [dispatch],
  );
  /**
   * guarda en el estado exoense
   * @param {obj} objPayroll payroll expense
   */
  const addExpense = useCallback(
    (objPayroll) => {
      objPayroll.ingreso = false;

      setNoveltiesData((prevState) => {
        return [...prevState, objPayroll];
      });

      dispatch(addExpenseAction(objPayroll));
    },
    [dispatch],
  );
  /**
   * actualiza en el estado income
   * @param {obj} objPayroll payroll income
   */
  const updateIncome = useCallback(
    (objPayroll) => {
      objPayroll.ingreso = true;
      const filteredArray = noveltiesData.filter((d) => d.id !== objPayroll.id);
      filteredArray.push(objPayroll);
      setNoveltiesData(filteredArray);
      dispatch(updateIncomeAction(filteredArray));
    },
    [dispatch, noveltiesData],
  );
  /**
   * actualiza en el estado deducciones
   * @param {obj} objPayroll payroll expense
   */
  const updateExpense = useCallback(
    (objPayroll) => {
      objPayroll.ingreso = false;
      const filteredArray = noveltiesData.filter((d) => d.id !== objPayroll.id);
      filteredArray.push(objPayroll);
      setNoveltiesData(filteredArray);
      dispatch(updateExpenseAction(filteredArray));
    },
    [dispatch, noveltiesData],
  );
  /**
   * elimina un ingreso de el store por id del ingreso , se recibe un objeto el cual se hace la validacion por id del ingreso a eliminar
   * @param {int} id id income
   */
  const deleteIncome = (id) => {
    const arrayFiltered = noveltiesData.map((d) => {
      if (d.id === id.id) {
        return {
          ...d,
          idstate: 1,
        };
      } else {
        return {
          ...d,
        };
      }
    });
    dispatch(updateIncomeAction(arrayFiltered));
    setNoveltiesData(arrayFiltered);
  };
  /**
   * elimina un ingreso de el store por id de la deducción ,se recibe un objeto del cual se debe obtener el id de la deducción
   * @param {int} id id income
   */
  const deleteExpense = (id) => {
    const arrayFiltered = noveltiesData.map((d) => {
      if (d.id === id.id) {
        return {
          ...d,
          idstate: 1,
        };
      } else {
        return {
          ...d,
        };
      }
    });
    dispatch(updateIncomeAction(arrayFiltered));
    setNoveltiesData(arrayFiltered);
  };

  /**
   * Marca Edición de empleado como reintegro
   */
  const setReintegreStatus = useCallback(
    (status) => {
      dispatch(setReintegreStatusAction(status));
    },
    [dispatch],
  );

  return {
    basicData,
    paymentData,
    contractData,
    noveltiesData,
    setBasicData,
    setContractData,
    setPaymentData,
    setFormCompleted,
    setNoveltiesData,
    loadingRegister,
    formCompleted,
    saveBasicData,
    savePaymentData,
    saveContractData,
    saveNoveltiesData,
    registerReduxForm,
    getContractTypes,
    getPaymentPeriod,
    getPaymentMethods,
    getListBranch,
    getBanks,
    getWorkerTypes,
    getWorkerSubTypes,
    getRetireCauses,
    resetForm,
    resetFormRedux,
    isEditing,
    employeeDetail,
    sendSupportToMail,
    lastSupports: employeeDetail?.lastSupports || null,
    responseCreateProcess,
    saveResponseCreate,
    // update, create process
    updateEmployeeProcess,
    createEmployeeProcess,
    // INCOMES EXPENSES
    addIncome,
    updateIncome,
    addExpense,
    updateExpense,
    deleteIncome,
    deleteExpense,
    setReintegreStatus,
  };
};
