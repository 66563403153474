import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { useEmployees } from '../../../../hooks/useEmployees';
import SelectForm from '../../../../components/select/select.component';
import InputForm from '../../../../components/input/input.component';
import CustomAutoComplete from '../../../../components/autocomplete/autocomplete.component';
import { useSelector } from 'react-redux';

import {
  regexLettersAndNumbers,
  regexOnlyNumbers,
  regexText,
  regexEmail,
  regexPhone,
} from '../../../../helpers/customRegex.hepers';

const useStyles = makeStyles((theme) => ({
  buttonSuccess: {
    backgroundColor: '#2CC63E',
    color: theme.palette.primaryColor,
    '&:hover': {
      backgroundColor: '#145e1c',
    },
    width: '100%',
    height: 50,
    textTransform: 'none',
    fontWeight: 600,
    fontSize: 18,
  },
}));

const BasicDataEmployee = React.memo(
  ({
    basicData,
    setBasicData,
    setFormCompleted,
    saveBasicData,
    isEditing,
    setActiveStep,
    haveAnySupport,
    resetFormRedux,
    setTabSelected,
    submitEmployee,
    simpleValidator,
    validateAllForms,
  }) => {
    const classes = useStyles();

    const [citiesOptions, setCitiesOptions] = useState([]);
    // custom hook
    const {
      existAnyEmployeeByDocument,
      changeEditingStatus,
      changeOpenRegisterEmployee,
      employeeDetail,
    } = useEmployees();

    // Observables de la store (redux)
    const documentTypesList = useSelector(
      (state) => state.configReducer.getDocumentTypesResponse || [],
    ).filter((documentType) => documentType.value !== '6');
    const statesList = useSelector(
      (state) => state.configReducer.getStatesResponse || [],
    );
    const citiesList = useSelector(
      (state) => state.configReducer.getAllCitiesResponse || [],
    );
    const employeesResumeList = useSelector(
      (state) => state.employeeReducer.employeesResumeList || [],
    );

    const [, forceUpdate] = useState();

    /**
     * Cargue inicial
     */
    useEffect(() => {
      window.scrollTo(0, 0);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Sincroniza nuevo valor de Input con state
     * @param {*} e Evento
     */
    const syncChanges = (e) => {
      const value = e.target.value;
      const name = e.target.name;

      if (
        name === 'diantipodocumentoidentidadid' &&
        basicData?.diantipodocumentoidentidadid === '7' &&
        value !== '7'
      ) {
        setBasicData({
          ...basicData,
          [name]: value,
          documento: basicData?.documento.replace(regexOnlyNumbers, ''),
        });
      } else {
        setBasicData({
          ...basicData,
          [name]: value,
        });
      }
    };

    /**
     * Sincroniza nuevo valor autocomplete con state
     * @param {*} event Evento
     * @param {*} values Nuevo valor
     * @param {*} id Identificador elemnto
     */
    const syncAutoCompleteChanges = (event, values, id) => {
      event.preventDefault();
      setBasicData({
        ...basicData,
        [id]: values || null,
      });
    };

    /**
     * Evento, Escucha cambio en listado de departamentos
     * @param {*} event Evento
     * @param {*} values Nuevo valor
     */
    const changeState = (event, values) => {
      event.preventDefault();
      setBasicData({
        ...basicData,
        departamento: values || null,
        municipio: null,
      });

      var newCities = citiesList.filter(
        (c) => c.departamentoid?.toString() === values?.value,
      );

      newCities = newCities.map((item) => {
        return {
          value: item.id.toString(),
          text: item.descripcion.toUpperCase(),
        };
      });
      setCitiesOptions(newCities);
    };

    /**
     * Limpia caracteres invalidos para valores numericos
     * @param {*} e Evento
     */
    const validateOnlyNumbers = (e) => {
      const id = !!e.target.id ? e.target.id : e.target.name;
      let value = e.target.value;
      value = value.replace(regexOnlyNumbers, '');
      setBasicData((prevState) => ({
        ...prevState,
        [id]: value,
      }));
    };

    /**
     * Limpia caracteres invalidos, solo letras y numeros
     * @param {*} e Evento
     */
    const validateLettersAndNumbers = (e) => {
      const id = !!e.target.id ? e.target.id : e.target.name;
      let value = e.target.value;
      value = value.replace(regexLettersAndNumbers, '');
      setBasicData((prevState) => ({
        ...prevState,
        [id]: value,
      }));
    };

    /**
     * Limpia caracteres invalidos para valores alfabeticos
     * @param {*} e Evento
     */
    const validateAlphabetic = (e) => {
      const id = !!e.target.id ? e.target.id : e.target.name;
      let value = e.target.value;
      value = value.replace(regexText, '');
      setBasicData((prevState) => ({
        ...prevState,
        [id]: value,
      }));
    };

    /**
     * Limpia caracteres invalidos para un correo electrónico
     * @param {*} e Evento
     */
    const validateEmail = (e) => {
      const id = !!e.target.id ? e.target.id : e.target.name;
      let value = e.target.value;
      value = value.replace(regexEmail, '');
      setBasicData((prevState) => ({
        ...prevState,
        [id]: value,
      }));
    };

    /**
     * Limpia caracteres invalidos para telefono
     * @param {*} e Evento
     */
    const validatePhone = (e) => {
      const id = !!e.target.id ? e.target.id : e.target.name;
      let value = e.target.value;
      value = value.replace(regexPhone, '');
      setBasicData((prevState) => ({
        ...prevState,
        [id]: value,
      }));
    };

    /**
     * Busco si eiste un usuario con el mismo documento.
     * actualiza formulario
     * @param {*} e
     */
    const onBlurDocument = (e) => {
      e.preventDefault();
      const document = basicData.documento;
      const typeDoc = basicData.diantipodocumentoidentidadid;

      if (
        document !== '' &&
        typeDoc !== '' &&
        employeesResumeList &&
        (isEditing ? employeeDetail?.documento !== document : true)
      ) {
        existAnyEmployeeByDocument(
          typeDoc,
          document,
          cleanDocument,
          redirectToRetiredEmployees,
        );
      }
    };

    const cleanDocument = () => {
      setBasicData((prevState) => {
        return { ...prevState, documento: '' };
      });
    };

    const redirectToRetiredEmployees = () => {
      setTabSelected(2);
      changeEditingStatus(false);
      resetFormRedux();
      changeOpenRegisterEmployee(false);
    };

    /**
     * Captura y guarda información del empleado en el formulario
     * @param {*} e Evento
     */
    const onSubmit = (e) => {
      e.preventDefault();

      if (!simpleValidator.allValid()) {
        simpleValidator.showMessages();
        forceUpdate(Math.random());
      }

      submitEmployee(e);
    };

    /**
     * Captura y guarda información del empleado
     * @param {*} e Evento
     */
    const nextStep = (e) => {
      e.preventDefault();

      validateAllForms();
      if (simpleValidator.allValid()) {
        setFormCompleted((prevState) => {
          return {
            ...prevState,
            basicData: true,
          };
        });
        saveBasicData(basicData);
        setActiveStep((prevState) => {
          return prevState + 1;
        });
      } else {
        simpleValidator.showMessages();
        forceUpdate(Math.random());
      }
    };

    return (
      <>
        <br></br>
        <form id={'step-form'} onSubmit={onSubmit}>
          <Grid>
            <Grid
              container
              direction="column"
              style={{ maxWidth: '70%', margin: '1% auto' }}
            >
              <span
                style={{
                  color: '#7DD1F8',
                  textAlign: 'center',
                  fontSize: '18px',
                }}
              >
                Ten en cuenta que una vez transmitas el empleado a DIAN, no
                podrás modificar los datos "Tipo de Documento" y "Número de
                documento".
              </span>
              <Grid item md={12} sm style={{ margin: '1% 0 ' }}>
                <div className="lineDivision"></div>
              </Grid>
            </Grid>
            <Grid style={{ maxWidth: '60%', margin: '0 auto' }}>
              <Grid container spacing={2}>
                <Grid item lg md={10} sm={12} xs={12}>
                  <SelectForm
                    disabled={!!haveAnySupport && isEditing}
                    label={'Tipo documento *'}
                    name="diantipodocumentoidentidadid"
                    value={basicData.diantipodocumentoidentidadid}
                    options={documentTypesList}
                    validator={simpleValidator}
                    validateOptions={'required'}
                    onChange={(e) => syncChanges(e)}
                  />
                </Grid>

                {/* Numero de documento */}
                <Grid item lg md={10} sm={12} xs={12}>
                  <InputForm
                    disabled={!!haveAnySupport && isEditing}
                    name="documento"
                    label={'Número de documento *'}
                    maxLength={20}
                    value={basicData.documento}
                    onChange={(e) => {
                      basicData.diantipodocumentoidentidadid === '7'
                        ? validateLettersAndNumbers(e)
                        : validateOnlyNumbers(e);
                    }}
                    onBlur={(e) => {
                      onBlurDocument(e);
                    }}
                    validator={simpleValidator}
                    validateOptions={'required|min:4|max:20'}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item lg md={10} sm={12} xs={12}>
                  <InputForm
                    name={'primerApellido'}
                    label={'Primer apellido *'}
                    maxLength={60}
                    onChange={(event) => {
                      validateAlphabetic(event);
                    }}
                    value={basicData.primerApellido}
                    validator={simpleValidator}
                    validateOptions={'required|min:3|max:60'}
                  />
                </Grid>

                <Grid item lg md={10} sm={12} xs={12}>
                  <InputForm
                    name={'segundoApellido'}
                    label={'Segundo apellido'}
                    maxLength={60}
                    onChange={(event) => {
                      validateAlphabetic(event);
                    }}
                    value={basicData.segundoApellido}
                    validator={simpleValidator}
                    validateOptions={'min:3|max:60'}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item lg md={10} sm={12} xs={12}>
                  <InputForm
                    name={'primerNombre'}
                    label={'Primer nombre *'}
                    maxLength={60}
                    onChange={(event) => {
                      validateAlphabetic(event);
                    }}
                    value={basicData.primerNombre}
                    validator={simpleValidator}
                    validateOptions={'required|min:3|max:60'}
                  />
                </Grid>

                <Grid item lg md={10} sm={12} xs={12}>
                  <InputForm
                    name={'otroNombre'}
                    label={'Otros nombres'}
                    maxLength={60}
                    onChange={(event) => {
                      validateAlphabetic(event);
                    }}
                    value={basicData.otroNombre}
                    validator={simpleValidator}
                    validateOptions={'min:3|max:60'}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item lg md={10} sm={12} xs={12}>
                  <InputForm
                    name="correoelectronico"
                    maxLength={200}
                    label={'Correo electrónico'}
                    value={basicData.correoelectronico}
                    onChange={(e) => {
                      validateEmail(e);
                    }}
                    validator={simpleValidator}
                    validateOptions={'correo|min:3|max:200'}
                  />
                </Grid>

                <Grid item lg md={10} sm={12} xs={12}>
                  <InputForm
                    name={'telefono'}
                    label={'Teléfono *'}
                    maxLength={15}
                    onChange={(event) => {
                      validatePhone(event);
                    }}
                    value={basicData.telefono}
                    validator={simpleValidator}
                    validateOptions={'required|min:7|max:15'}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item lg md={10} sm={12} xs={12} style={{ margin: '8px' }}>
                  <CustomAutoComplete
                    name="departamento"
                    label="Departamento *"
                    value={basicData.departamento}
                    options={statesList}
                    textNoOption="No se encontraron resultados."
                    onChange={(event, values) => {
                      changeState(event, values);
                    }}
                    nameValidator={'departamento'}
                    validator={simpleValidator}
                    validateOptions={'required'}
                  />
                </Grid>

                <Grid item lg md={10} sm={12} xs={12} style={{ margin: '8px' }}>
                  <CustomAutoComplete
                    name="municipio"
                    label="Municipio *"
                    value={basicData.municipio}
                    options={citiesOptions}
                    textNoOption="No se encontraron resultados."
                    onChange={(event, values) => {
                      syncAutoCompleteChanges(event, values, 'municipio');
                    }}
                    nameValidator={'municipio'}
                    validator={simpleValidator}
                    validateOptions={'required'}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item lg md sm xs={12}>
                  <InputForm
                    name={'direccion'}
                    label={'Dirección *'}
                    maxLength={100}
                    onChange={(event) => {
                      syncChanges(event);
                    }}
                    value={basicData.direccion}
                    validator={simpleValidator}
                    validateOptions={'required|min:3|max:100'}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3} alignItems="center">
                <Grid item lg md={10} sm={12} xs={12}></Grid>
                <Grid item lg md={10} sm={12} xs={12}>
                  <Button
                    className={classes.buttonSuccess}
                    onClick={(e) => nextStep(e)}
                    type={'button'}
                  >
                    Siguiente
                  </Button>
                </Grid>
              </Grid>
              {/*  Footer */}
              <div className="spacingInvoice"></div>
            </Grid>
          </Grid>
        </form>
      </>
    );
  },
);

export default BasicDataEmployee;
