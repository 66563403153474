import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import { CardContent } from '@material-ui/core';
import CustomProgress from '../../components/Progress/progress.component';
import { LICENSE_SHOP_URL } from '../../config/config';
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 500,
    backgroundColor: theme.palette.thirdColor,
    color: theme.palette.primaryColor,
    fontWeight: 300,
  },
  content: {
    display: 'flex',
  },
  title: {
    color: theme.palette.primaryColor,
    fontSize: 45,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 800,
    [theme.breakpoints.down('lg')]: {
      fontSize: 30,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: 45,
    },
  },
  subTitle: {
    color: theme.palette.primaryColor,
    fontSize: 16,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 600,
    paddingTop: '5%',
    paddingLeft: '5%',
    [theme.breakpoints.down('lg')]: {
      fontSize: 15,
    },

    [theme.breakpoints.down('md')]: {
      fontSize: 16,
    },
  },
  buttonInfo: {
    backgroundColor: '#F5D13D',
    color: theme.palette.primaryColor,
    fontSize: 16,
    fontWeight: 600,
    padding: 1,
    border: 'none',
    borderRadius: 5,
    fontFamily: theme.typography.fontFamily,
    width: '50%',
    height: 35,
    cursor: 'pointer',
  },
  actions: {
    textAlign: 'center',
    justifyContent: 'center',
  },
}));

export const LicenseInfoCard = React.memo(({ title, subtitle, loading }) => {
  const classes = useStyles();
  const handleRedirect = () => {
    window.location.href = LICENSE_SHOP_URL;
  };

  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        {loading ? (
          <CustomProgress size={20} color={'white'} />
        ) : (
          <div className={classes.title}>{title ?? 0}</div>
        )}

        <div className={classes.subTitle}>{subtitle}</div>
      </CardContent>
      <CardActions disableSpacing className={classes.actions}>
        <button
          type="button"
          className={classes.buttonInfo}
          onClick={handleRedirect}
        >
          Comprar
        </button>
      </CardActions>
    </Card>
  );
});
