import React from 'react';

import { format } from 'date-fns';
import PulseLoader from 'react-spinners/PulseLoader';

import { IconButton } from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';

import PendingTransferIcon from '../../images/icons/cloud.png';
import PendingIcon from '../../images/icons/pending.png';
import RetiredIcon from '../../images/icons/retired.png';
import TransferedIcon from '../../images/icons/transfered.png';
import TransferedBlankIcon from '../../images/icons/transferedBlank.png';
import VerifiedIcon from '../../images/icons/verified.png';
import { formatDate } from '../../utils/general';
import CustomTooltip from '../tooltip/tooltip.component';
import TooltipMessage from '../tooltip/tootltip-message.component';
import ErrorStatus from './errorTooltip/ErrorStatus';

export const PayrollStatus = ({ item, isSelected }) => {
  return (
    <>
      {item.retirado && (
        <CustomTooltip
          message={
            <TooltipMessage
              title="Empleado retirado"
              message={`Retiraste este empleado el ${
                formatDate(item.fecharetiro, 'dd/MM/yyyy') || ''
              }`}
              bgColor={'#ED8D84'}
              href={null}
            />
          }
          iconEnvolved={
            <IconButton edge="end" aria-label="comments">
              <img src={RetiredIcon} alt="Rtr" />
            </IconButton>
          }
        />
      )}

      {item.favorito && (
        <IconButton edge="end" aria-label="comments">
          <StarIcon style={{ color: '#FFF' }} />
        </IconButton>
      )}

      {item.estadonominaid === 0 && (
        <CustomTooltip
          message={
            <TooltipMessage
              title="Nómina sin confirmar."
              message="Registraste la información del empleado, pero no confirmaste la nómina."
              bgColor="#FAFA87"
              href={null}
            />
          }
          iconEnvolved={
            <IconButton edge="end" aria-label="comments">
              <img src={PendingIcon} alt="Alternativo" />
            </IconButton>
          }
        />
      )}

      {item.estadonominaid === 1 && (
        <CustomTooltip
          message={
            <TooltipMessage
              title="Nómina Confirmada"
              message="La información del empleado está lista para ser transmitida a la DIAN"
              href={null}
            />
          }
          iconEnvolved={
            <IconButton edge="end" aria-label="comments">
              <img src={VerifiedIcon} alt="Alternativo" />
            </IconButton>
          }
        />
      )}

      {item.estadonominaid === 2 && (
        <CustomTooltip
          message={
            <TooltipMessage
              title="Transmisión a DIAN"
              message={
                <span>
                  Validación DIAN:
                  <span style={{ fontWeight: 'bold' }}> Aceptada </span>
                </span>
              }
              description={
                <div>
                  Fecha validacíon:
                  <span style={{ fontWeight: 'bold' }}>
                    {format(new Date(item.fechavalidaciondian), 'yyyy/MM/dd')}
                  </span>
                </div>
              }
              isPayrool={true}
              href={null}
              bgColor="#7DD1F8"
            />
          }
          iconEnvolved={
            <IconButton edge="end" aria-label="comments">
              {!isSelected ? (
                <img src={TransferedIcon} alt="Transmitido" />
              ) : (
                <img src={TransferedBlankIcon} alt="Transmitido" />
              )}
            </IconButton>
          }
        />
      )}

      {item.estadonominaid === 4 && (
        <CustomTooltip
          message={
            <TooltipMessage
              title="Pendiente de sincronización con DIAN."
              message="Este Item esta pendiente de sincronización con DIAN."
              bgColor="#FFF"
              href={null}
            />
          }
          iconEnvolved={
            <IconButton edge="end" aria-label="comments">
              <img src={PendingTransferIcon} alt="Alternativo" />
            </IconButton>
          }
        />
      )}

      {item.estadonominaid === 3 ||
        (item.estadonominaid === 6 && <ErrorStatus item={item} />)}

      {Number(item.estadonominaid) === 5 && (
        <IconButton edge="end" aria-label="comments">
          <PulseLoader color={'#ffffff'} size={10} />
        </IconButton>
      )}
    </>
  );
};
