import React, { useEffect, useState, useRef } from 'react';
import SimpleReactValidator from 'simple-react-validator';

import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DatePickerForm from '../../../../components/datepicker/datepicker.component';
import SwitchForm from '../../../../components/switch/switch.component';
import SelectForm from '../../../../components/select/select.component';
import InputForm from '../../../../components/input/input.component';
import TooltipMessage from '../../../../components/tooltip/tootltip-message.component';
import ButtonPrimary from '../../../../components/button/buttonPrimary.component';
import CustomProgress from '../../../../components/Progress/progress.component';
import CustomAutoComplete from '../../../../components/autocomplete/autocomplete.component';
import SimpleModal from '../../../../components/modal/simpleModal.component';
import { useSelector } from 'react-redux';

import { regexAlphaNumeric } from '../../../../helpers/customRegex.hepers.js';
import ModalSalary from './modalSalary';
import isEmpty from '../../../../utils/isEmpty';
import { RetireCard } from './retireCard';
import { ConfirmAlert2 } from '../../../../helpers/alert.helpers';
import { format } from 'date-fns';
import { reactValidatorOptions } from '../../../../helpers/simpleReactValidator';
import { NumberFormatCustomMax9 } from '../../../../utils/numberFormat';

const useStyles = makeStyles((theme) => ({
  paddingIcons: {
    paddingTop: '5px',
  },
  letter: {
    color: theme.palette.primaryColor,
  },
  letterInformation: {
    color: theme.palette.primaryColor,
    fontFamily: theme.palette.fontFamily,
    fontWeight: 400,
    fontSize: 18,
    textAlign: 'justify',
  },
  link: {
    color: 'White',
    fontSize: '16px',
    padding: '7px',
    textAlign: 'center',
  },
  backgroundContent: {
    color: theme.palette.primaryColor,
  },
  subTitle: {
    color: theme.palette.primaryColor,
    fontSize: 18,
    textAlign: 'center',
  },
  title: {
    color: theme.palette.thirdColor,
    fontSize: 25,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  dialogConfirm: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    '& .MuiPaper-root': {
      backgroundColor: '#393939',
      color: theme.palette.primaryColor,
    },
  },
  btnBlue: {
    width: '25%',
    float: 'right',
  },
  divRegister: {
    background: theme.palette.seventhColor /* Can be in percentage also. */,
    height: 'auto',
    margin: '0 auto',
    padding: '10px',
    position: 'relative',
    textAlign: 'center',
    color: '#272727',
    fontFamily: theme.palette.fontFamily,
    fontWeight: 'bold',
    borderRadius: '5px',
  },
  styleText: {
    fontFamily: theme.palette.fontFamily,
    color: '#FFFFFF',
    fontSize: 14,
  },
  styleIcon: {
    color: '#FFFFFF',
    cursor: 'pointer',
  },
  datanotfound: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    alignItems: 'center',
    textAlign: 'center',
  },
  dialog: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    '& .MuiPaper-root': {
      backgroundColor: '#272727',
      color: theme.palette.primaryColor,
      height: 480,
      width: 1000,
      borderRadius: '5px',
    },
  },
  titleInformation: {
    color: theme.palette.thirdColor,
    fontSize: 25,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  textBarBotton: {
    fontFamily: theme.palette.fontFamily,
    color: '#FFFFFF',
    fontSize: 10,
    fontWeight: 'bold',
    letterSpacing: 0,
    textAlign: 'center',
  },
  buttonSuccess: {
    backgroundColor: '#2CC63E',
    color: theme.palette.primaryColor,
    '&:hover': {
      backgroundColor: '#145e1c',
    },
    width: '100%',
    height: 50,
    textTransform: 'none',
    fontWeight: 600,
    fontSize: 18,
  },
  blueText: {
    color: theme.palette.thirdColor,
  },
  margin: {
    margin: '0 auto',
  },
  marginForm: {
    maxWidth: '60%',
    margin: '0 auto',
  },
}));

const ContractData = React.memo(
  ({
    contractData,
    setContractData,
    setFormCompleted,
    saveContractData,
    getWorkerTypes,
    getWorkerSubTypes,
    getContractTypes,
    getPaymentPeriod,
    setActiveStep,
    getListBranch,
    isEditing,
    submitEmployee,
    getRetireCauses,
    simpleValidator,
    validateAllForms,
  }) => {
    const classes = useStyles();

    const validatorRetire = useRef(
      new SimpleReactValidator(reactValidatorOptions),
    ).current;

    const [, forceUpdate] = useState();
    const [citiesOptions, setCitiesOptions] = useState([]);
    const [branchesOptions, setBranchesOptions] = useState([]);
    const [userAction, setUserAction] = useState(null);
    const [openModalChoose, setOpenModalChoose] = useState(false);
    const [openModalAddSalary, setOpenModalAddSalary] = useState(false);
    const [openModalEditSalary, setOpenModalEditSalary] = useState(false);
    const [disabledForm] = useState(false);
    const [openModalRetire, setOpenModalRetire] = useState(false);
    const [dataRetire, setDataRetire] = useState({
      fecharetiro: null,
      causalretiroid: '',
    });

    const statesList = useSelector(
      (state) => state.configReducer.getStatesResponse || [],
    );

    const citiesList = useSelector(
      (state) => state.configReducer.getAllCitiesResponse || [],
    );

    const { reintegreStatus } = useSelector((s) => s.employeeReducer);
    const [isSalaryEditable, setIsSalaryEditable] = useState(false);

    /**
     * Cargue inicial
     */
    useEffect(() => {
      window.scrollTo(0, 0);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (!branchesOptions.length && getListBranch && getListBranch.length) {
        const destructuredValues = getListBranch.map((d) => {
          return { value: d.id, text: d.nombre };
        });
        destructuredValues.sort((x, y) => {
          let fa = x.text.toLowerCase(),
            fb = y.text.toLowerCase();
          if (fa > fb) {
            return 1;
          }
          if (fa < fb) {
            return -1;
          }
          return 0;
        });
        setBranchesOptions(destructuredValues);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getListBranch]);

    const handleCloseModalChoose = () => {
      setOpenModalChoose(false);
    };

    const handleCloseModalSalary = () => {
      setOpenModalAddSalary(false);
    };

    const handleCloseModalEditSalary = () => {
      setOpenModalEditSalary(false);
    };

    /**
     * Sincroniza nuevo valor de Input con state
     * @param {*} e Evento
     */
    const syncChanges = (e) => {
      const value = e.target.value;
      const name = e.target.name;

      setContractData({
        ...contractData,
        [name]: value,
      });
    };

    /**
     * Sincroniza nuevo valor de Input con state
     * @param {*} e Evento
     */
    const syncContractTypeChanges = (e) => {
      const value = e.target.value;
      const name = e.target.name;

      if (Number(value) === 2 && !isEmpty(contractData.fechaFinContrato)) {
        setContractData({
          ...contractData,
          [name]: value,
          fechaFinContrato: '',
        });
      } else {
        setContractData({
          ...contractData,
          [name]: value,
        });
      }
    };

    /**
     * Limpia caracteres invalidos para valores alfabeticos
     * @param {*} e Evento
     */
    const validateAlphabetic = (e) => {
      const id = !!e.target.id ? e.target.id : e.target.name;
      let value = e.target.value;
      value = value.replace(regexAlphaNumeric, '');
      setContractData((prevState) => ({
        ...prevState,
        [id]: value,
      }));
    };

    /**
     * Limpia caracteres invalidos para valores numericos
     * con 16 enteros y 6 decimales
     * @param {*} e Evento
     */
    const syncChangesValue = ({ target }) => {
      setContractData((prevState) => ({
        ...prevState,
        [target.name]: target.value,
      }));
    };

    /**
     * Sincroniza nuevo valor de state con la seleccion del usuario para la edicion del salario
     * @param {*} e Evento
     */
    const syncUserSelection = (e) => {
      const value = e.target.value;
      //const name = e.target.name;
      setUserAction(value);
    };

    /**
     * Sincroniza nuevo valor de checbox con form
     * @param {*} e Evento
     */
    const syncCheckChange = (e) => {
      const name = e.target.name;
      const checked = e.target.checked;
      setContractData({
        ...contractData,
        [name]: checked,
      });
    };

    /**
     * Sincroniza nuevo valor autocomplete con state
     * @param {*} event Evento
     * @param {*} values Nuevo valor
     * @param {*} id Identificador elemnto
     */
    const syncAutoCompleteChanges = (event, values, id) => {
      event.preventDefault();
      setContractData({
        ...contractData,
        [id]: values,
      });
    };

    /**
     * Sincroniza nuevo valor datePicker
     * @param {*} id Identificador
     * @param {*} date Nueva Fecha
     * @param {*} name Nombre datePicker
     */
    const handleDateFormPicket = (id, date, name) => {
      setContractData({
        ...contractData,
        [id]: new Date(date).toISOString(),
      });
    };

    /**
     * Evento, Escucha cambio en listado de departamentos
     * @param {*} event Evento
     * @param {*} values Nuevo valor
     */
    const changeCompanyState = (event, values) => {
      event.preventDefault();
      setContractData({
        ...contractData,
        departamento: values,
        municipio: null,
      });

      var newCities = citiesList.filter(
        (c) => c.departamentoid?.toString() === values?.value,
      );

      newCities = newCities.map((item) => {
        return {
          value: item.id.toString(),
          text: item.descripcion.toUpperCase(),
        };
      });

      setCitiesOptions(newCities);
    };

    /**
     * Captura y guarda información de contrato del empleado
     * @param {*} e Evento
     */
    const onSubmit = (e) => {
      e.preventDefault();

      if (!simpleValidator.allValid()) {
        simpleValidator.showMessages();
        forceUpdate(Math.random());
      }

      submitEmployee(e);
    };

    /**
     * Captura y guarda información de contrato del empleado
     * @param {*} e Evento
     */
    const nextStep = (e) => {
      e.preventDefault();
      validateAllForms();
      if (simpleValidator.allValid()) {
        saveContractData(contractData);
        setFormCompleted((prevState) => {
          return {
            ...prevState,
            contractData: true,
          };
        });
        setActiveStep((prevState) => {
          return prevState + 1;
        });
      } else {
        simpleValidator.showMessages();
        forceUpdate(Math.random());
      }
    };

    /**
     * Valida formularios, muestra paso anterior
     * @param {*} e
     */
    const backStep = (e) => {
      validateAllForms();
      setActiveStep((prev) => {
        return prev - 1;
      });
    };

    const onSubmitModal = (e) => {
      e.preventDefault();

      if (simpleValidator.allValid()) {
        if (Number(userAction) === 1) {
          handleCloseModalChoose();
          setOpenModalEditSalary(true);
        } else if (Number(userAction) === 2) {
          handleCloseModalChoose();
          setOpenModalAddSalary(true);
        }
      } else {
        simpleValidator.showMessages();
        forceUpdate(Math.random());
      }
    };

    const onClickSalary = () => {
      if (isEditing && !reintegreStatus) {
        setOpenModalChoose(true);
        setIsSalaryEditable(true);
      }
    };

    /**
     * Elimina fecha  de retiro empleado
     */
    const handleDeleteRetire = () => {
      ConfirmAlert2(
        `¿Estás seguro que deseas revertir el retiro del empleado? esto lo dejará nuevamente como empleado activo`,
        confirmRetireProcess,
        'Si',
      );
    };

    /**
     * Confirma marcar empleado como retirado
     */
    const confirmRetireProcess = () => {
      setContractData({
        ...contractData,
        fechaRetiro: null,
        causalretiroid: null,
        causalretiro: null,
      });
    };

    /**
     * Abre modal para cambiar fecha de retiro
     */
    const handleEditRetire = () => {
      setDataRetire({
        fecharetiro: contractData.fechaRetiro,
        causalretiroid: contractData.causalretiroid,
      });

      setOpenModalRetire(true);
    };

    /**
     * Cierra modal para cambiar fecha de retiro
     */
    const handleCloseModalRetire = () => {
      setOpenModalRetire(false);
    };

    /**
     * Actualiza Fecha y causal de retiro
     * @param {*} e
     */
    const confirmDataRetire = (e) => {
      e.preventDefault();

      if (!validatorRetire.allValid()) {
        validatorRetire.showMessages();
        forceUpdate(Math.random());
      }

      const causal = getRetireCauses.find(
        (c) => c.id === Number(dataRetire.causalretiroid),
      );

      let newDate = !!dataRetire.fecharetiro
        ? format(new Date(dataRetire.fecharetiro), 'yyyy/MM/dd')
        : null;

      setContractData({
        ...contractData,
        fechaRetiro: newDate,
        causalretiroid: dataRetire.causalretiroid,
        causalretiro: causal ?? null,
        fechaFinContrato: newDate,
      });

      handleCloseModalRetire();
    };

    /**
     * Sincroniza nueva causal seleccionada con state
     * @param {*} param0
     */
    const handleChangeInputCausal = ({ target }) => {
      setDataRetire((prev) => ({
        ...prev,
        causalretiroid: target.value,
      }));
    };

    /**
     * Sincroniza nuevo valor datePicker
     * @param {*} id Identificador
     * @param {*} date Nueva Fecha
     * @param {*} name Nombre datePicker
     */
    const handleChangeDateRetire = (id, date, name) => {
      setDataRetire((prev) => ({
        ...prev,
        fecharetiro: new Date(date),
      }));
    };

    /**
     * Obtener fecha minima para fecha vencimiento
     */
    const getDateMinRetire = (dateCurrent) => {
      if (isEmpty(dateCurrent)) {
        return null;
      }
      let date = new Date(dateCurrent);
      return date;
    };

    /**
     * Obtener fecha maxima de ingreso
     */
    const getDateMaxEntry = () => {
      const finContrato = isEmpty(contractData.fechaFinContrato)
        ? null
        : contractData.fechaFinContrato;
      const retiro = isEmpty(contractData.fechaRetiro)
        ? null
        : contractData.fechaRetiro;

      let date = null;
      if (retiro !== null) {
        date = new Date(retiro);
      } else if (finContrato !== null) {
        date = new Date(finContrato);
      }

      return date;
    };

    // Modal edicion salario
    const modalChoosing = () => {
      return (
        <form>
          <Grid container>
            <Grid
              item
              className={classes.letter}
              lg={12}
              md={12}
              sm={12}
              xs={12}
            >
              <Grid item lg={12} md={12} sm={12} xs={12} className="modalEmail">
                Estas a punto de modificar el salario, ten en cuenta las
                siguientes opciones:
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} className="modalEmail">
                <span className={classes.blueText}>Corregir: </span> Usa esta
                opción en caso de que inicialmente hayas ingresado el salario de
                manera errada y necesites corregirlo.
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} className="modalEmail">
                <span className={classes.blueText}>Aumentar: </span>
                Usa esta opción en caso de que hayas aumentado el salario de tu
                empleado.
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12} className="modalEmail">
                ¿Que deseas hacer?
              </Grid>
              <Grid className={classes.margin} md={5} sm={5} xs={5}>
                <SelectForm
                  label={'Seleccione *'}
                  name="userAction"
                  value={userAction}
                  options={[
                    { value: '1', text: 'Corregir' },
                    { value: '2', text: 'Aumentar' },
                  ]}
                  validator={simpleValidator}
                  validateOptions={'required'}
                  onChange={(e) => syncUserSelection(e)}
                />
              </Grid>
            </Grid>
            <Grid item lg={4} md={4} sm className={classes.margin}>
              <ButtonPrimary
                text={'Continuar'}
                onClick={(e) => onSubmitModal(e)}
                type={'button'}
              />
            </Grid>
            <br></br>
          </Grid>
        </form>
      );
    };

    /**
     * Modifica el salario y la fecha cuando se corrige
     * @param {*} salario Nuevo salario
     */
    const updateSalary = (salario) => {
      setContractData({
        ...contractData,
        salario: salario,
      });
      handleCloseModalEditSalary();
    };

    /**
     * Modifica el salario y la fecha cuando se aumenta
     * @param {*} salario Nuevo salario
     * @param {*} fechaSalario Fecha a partir de la cual aplica el salario
     */
    const updateAddSalary = (salario, fechaSalario) => {
      setContractData({
        ...contractData,
        salario: salario,
        fechaSalario: fechaSalario,
      });
      handleCloseModalSalary();
    };

    const modalRetire = () => {
      return (
        <Grid container>
          <Grid item lg={12} md={12}>
            <span
              style={{
                padding: '3%',
                fontSize: '17px',
              }}
            >
              Por favor ingresa la información de retiro
            </span>
          </Grid>
          <Grid item lg={6} md={6} sm>
            <DatePickerForm
              label={'Fecha de retiro'}
              value={dataRetire.fecharetiro}
              id={'fechaRetiro'}
              name={'fechaRetiro'}
              minDate={getDateMinRetire(contractData.fechaIngreso)}
              onChange={handleChangeDateRetire}
              validator={validatorRetire}
              validateOptions={'required'}
            />
          </Grid>
          <Grid item lg={6} md={6} sm>
            <SelectForm
              label={'Causal de retiro *'}
              name="causalRetiroId"
              value={dataRetire.causalretiroid}
              options={getRetireCauses}
              validator={validatorRetire}
              validateOptions={'required'}
              onChange={handleChangeInputCausal}
            />
          </Grid>
          <Grid item lg={4} md={4} sm style={{ margin: '0 auto' }}>
            <ButtonPrimary
              text={'Continuar'}
              onClick={confirmDataRetire}
              type={'button'}
              style={{ color: '#FFF' }}
            />
          </Grid>
        </Grid>
      );
    };

    return (
      <>
        <br></br>
        {!branchesOptions || branchesOptions.length === 0 ? (
          <Grid container alignItems="center">
            <CustomProgress />
          </Grid>
        ) : (
          <form id={'step-form'} onSubmit={onSubmit}>
            <Grid className={classes.marginForm}>
              {/* Esto es para que se limpien las validaciones y se actualicen*/}
              {simpleValidator.purgeFields()}
              <Grid container spacing={2}>
                <Grid item lg md={10} sm={12} xs={12}>
                  <SelectForm
                    label={'Tipo de trabajador *'}
                    name="tipoTrabajador"
                    value={contractData.tipoTrabajador}
                    options={getWorkerTypes}
                    validator={simpleValidator}
                    validateOptions={'required'}
                    onChange={(e) => syncChanges(e)}
                  />
                </Grid>
                <Grid item lg md={10} sm={12} xs={12}>
                  <SelectForm
                    label={'Sub tipo de trabajador *'}
                    name="subTipoTrabajador"
                    value={contractData.subTipoTrabajador}
                    options={getWorkerSubTypes}
                    validator={simpleValidator}
                    validateOptions={'required'}
                    onChange={(e) => syncChanges(e)}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item lg md={10} sm={12} xs={12}>
                  <SelectForm
                    label={'Tipo contrato *'}
                    name="tipoContrato"
                    value={contractData.tipoContrato}
                    options={getContractTypes}
                    validator={simpleValidator}
                    validateOptions={'required'}
                    onChange={(e) => syncContractTypeChanges(e)}
                  />
                </Grid>
                <Grid item lg md={10} sm={12} xs={12}>
                  <InputForm
                    name="numeroContrato"
                    label={'Número contrato'}
                    maxLength={30}
                    value={contractData.numeroContrato}
                    onChange={(e) => {
                      syncChanges(e);
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item lg md={10} sm={12} xs={12}>
                  <DatePickerForm
                    label={'Fecha de ingreso *'}
                    value={contractData.fechaIngreso}
                    id={'fechaIngreso'}
                    name={'fechaIngreso'}
                    maxDate={getDateMaxEntry()} //Debe ser getDateMaxEntry() FR
                    onChange={handleDateFormPicket}
                    validator={simpleValidator}
                    validateOptions={'required'}
                  />
                </Grid>
                <Grid item lg md={10} sm={12} xs={12}>
                  {Number(contractData.tipoContrato) !== 2 ? (
                    <DatePickerForm
                      label={'Fecha finalizacion contrato *'}
                      value={contractData.fechaFinContrato}
                      id={'fechaFinContrato'}
                      name={'fechaFinContrato'}
                      onChange={handleDateFormPicket}
                      minDate={getDateMinRetire(contractData.fechaIngreso)}
                      validator={simpleValidator}
                      disabled={
                        isEmpty(contractData.fechaRetiro) ? false : true
                      }
                      validateOptions={
                        Number(contractData.tipoContrato) !== 2
                          ? 'required'
                          : 'void'
                      }
                    />
                  ) : (
                    ''
                  )}
                </Grid>
              </Grid>

              {!isEmpty(contractData.fechaRetiro) && (
                <Grid container>
                  <Grid item lg={12} xs={12} style={{ padding: '10px 8px' }}>
                    <RetireCard
                      fechaRetiro={contractData.fechaRetiro}
                      causalretirodescripcion={
                        contractData?.causalretiro?.descripcion ?? ''
                      }
                      handleDelete={handleDeleteRetire}
                      handleEdit={handleEditRetire}
                    />
                  </Grid>
                </Grid>
              )}

              <Grid container spacing={2}>
                <Grid item lg md={10} sm={12} xs={12}>
                  <InputForm
                    name="cargo"
                    label={'Cargo *'}
                    maxLength={100}
                    value={contractData.cargo}
                    onChange={(e) => {
                      validateAlphabetic(e);
                    }}
                    validator={simpleValidator}
                    validateOptions={'required'}
                  />
                </Grid>
                <Grid
                  item
                  lg
                  md={10}
                  sm={12}
                  xs={12}
                  onClick={onClickSalary}
                  onMouseEnter={() => setIsSalaryEditable(true)}
                  onMouseLeave={() => {
                    if (isEditing && !reintegreStatus) {
                      setIsSalaryEditable(false);
                    }
                  }}
                >
                  <InputForm
                    name="salario"
                    label={'Salario *'}
                    value={contractData.salario}
                    onChange={syncChangesValue}
                    onClick={onClickSalary}
                    disabled={
                      isEditing && !reintegreStatus && !isSalaryEditable
                    }
                    nameValidator={'Valor'}
                    validator={simpleValidator}
                    validateOptions={'required'}
                    inputComponent={NumberFormatCustomMax9}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item lg md={10} sm={12} xs={12}>
                  <SelectForm
                    label={'Periodicidad de pago *'}
                    name="dianPeriodoNomina"
                    value={contractData.dianPeriodoNomina}
                    options={getPaymentPeriod}
                    validator={simpleValidator}
                    validateOptions={'required'}
                    onChange={(e) => syncChanges(e)}
                  />
                </Grid>
                <Grid item lg md={10} sm={12} xs={12}>
                  <SwitchForm
                    name="esSalarioIntegral"
                    titleOn={'Salario integral'}
                    checked={contractData.esSalarioIntegral}
                    value={contractData.esSalarioIntegral}
                    onChange={(e) => syncCheckChange(e)}
                    txtTooltip={
                      <TooltipMessage
                        title={'¿Cuánto es un salario integral?'}
                        message={
                          'El salario integral se encuentra previsto en el numeral 2 del artículo 132 del Código Sustantivo del Trabajo.'
                        }
                        botton={null}
                        href={null}
                      />
                    }
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item lg md={10} sm={12} xs={12}>
                  <SwitchForm
                    name="esAltoRiesgo"
                    titleOn={'Trabajo de alto riesgo'}
                    checked={contractData.esAltoRiesgo}
                    value={contractData.esAltoRiesgo}
                    onChange={(e) => syncCheckChange(e)}
                    txtTooltip={
                      <TooltipMessage
                        title={'¿Qué quiere decir esto?'}
                        message={
                          'Son las actividades descritas en el decreto 2090 de 2003, o la norma que lo modifique, adicione o sustituya, las cuales pueden causar accidentes laborales severos.'
                        }
                        botton={null}
                        href={null}
                      />
                    }
                  />
                </Grid>
                <Grid item lg md={10} sm={12} xs={12}>
                  <SelectForm
                    label={'Sucursal *'}
                    name="sucursal"
                    value={contractData.sucursal}
                    options={branchesOptions}
                    validator={simpleValidator}
                    validateOptions={'required'}
                    onChange={(e) => syncChanges(e)}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item lg md={10} sm={12} xs={12}>
                  <CustomAutoComplete
                    name="departamento"
                    label={'Departamento lugar de trabajo *'}
                    value={contractData.departamento}
                    options={statesList}
                    textNoOption="No se encontraron resultados."
                    disabled={disabledForm}
                    onChange={(event, values) => {
                      changeCompanyState(event, values);
                    }}
                    nameValidator={'departamento'}
                    validator={simpleValidator}
                    validateOptions={'required'}
                  />
                </Grid>

                <Grid item lg md={10} sm={12} xs={12}>
                  <CustomAutoComplete
                    name="municipio"
                    label={'Municipio lugar de trabajo *'}
                    value={contractData.municipio}
                    options={citiesOptions}
                    textNoOption="No se encontraron resultados."
                    disabled={disabledForm}
                    onChange={(event, values) => {
                      syncAutoCompleteChanges(event, values, 'municipio');
                    }}
                    nameValidator={'municipio'}
                    validator={simpleValidator}
                    validateOptions={'required'}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item lg md={10} sm={12} xs={12}>
                  <InputForm
                    name={'direccion'}
                    label={'Dirección lugar de trabajo *'}
                    maxLength={100}
                    onChange={(event) => {
                      syncChanges(event);
                    }}
                    value={contractData.direccion}
                    disabled={disabledForm}
                    validator={simpleValidator}
                    validateOptions={'required|min:3|max:100'}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3} alignItems="center">
                <Grid item lg md={10} sm={12} xs={12}>
                  <Button
                    onClick={backStep}
                    className={classes.buttonSuccess}
                    type={'button'}
                  >
                    Anterior
                  </Button>
                </Grid>
                <Grid item lg md={10} sm={12} xs={12}>
                  <Button
                    className={classes.buttonSuccess}
                    onClick={(e) => nextStep(e)}
                    type={'button'}
                  >
                    Siguiente
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        )}

        <SimpleModal
          onClose={handleCloseModalChoose}
          open={openModalChoose}
          component={modalChoosing}
          title={'Editar salario'}
          showCloseButton={true}
        />
        <SimpleModal
          onClose={handleCloseModalSalary}
          open={openModalAddSalary}
          title="Aumentar salario"
          component={() => {
            return (
              <ModalSalary
                aumento={true}
                salario={contractData.salario}
                updateSalary={updateAddSalary}
              />
            );
          }}
          showCloseButton={true}
        />
        <SimpleModal
          onClose={handleCloseModalEditSalary}
          open={openModalEditSalary}
          title="Corregir salario"
          width="30%"
          component={() => {
            return (
              <ModalSalary
                aumento={false}
                salario={contractData.salario}
                updateSalary={updateSalary}
              />
            );
          }}
          showCloseButton={true}
        />
        {/* Modal Retiro empleado */}
        <SimpleModal
          onClose={handleCloseModalRetire}
          open={openModalRetire}
          title="Retirar Empleado"
          component={modalRetire}
        />
      </>
    );
  },
);

export default ContractData;
