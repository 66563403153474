import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import RoomIcon from '@material-ui/icons/Room';
import PhoneIcon from '@material-ui/icons/Phone';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import EditIcon from '@material-ui/icons/Edit';
import StarIcon from '@material-ui/icons/Star';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import SimpleReactValidator from 'simple-react-validator';
// react-router
import { useHistory } from 'react-router-dom';
// custom components}
import SelectForm from '../../../../../components/select/select.component';
import CustomProgress from '../../../../../components/Progress/progress.component';
import CollapsePanel from '../../../../../components/collapse/collapsestyles.component';
import DatePickerForm from '../../../../../components/datepicker/datepicker.component';
import SimpleModal from '../../../../../components/modal/simpleModal.component';
import ButtonPrimary from '../../../../../components/button/buttonPrimary.component';
import AditionalInformation from './aditionalInformation';
import PaymentsDetail from './paymentsDetail';
import { getFullName } from '../../../../../helpers/functions.helpers';
import documentDescription from '../../../../../utils/typeDocument';
import { formatDate } from '../../../../../utils/general.js';
import { formatCurrencyColombia } from '../../../../../helpers/formatCurrency';
// custom hook
import { useRegisterEmployee } from '../../../../../hooks/useRegisterEmployee';
import { usePayroll } from '../../../../../hooks/usePayroll';
// icons
import retireEmployeeIcon from '../../../../../images/icons/retireEmployeeIcon.png';
import arrowRight from '../../../../../images/icons/arrowRight.png';

import { ConfirmAlert2 } from '../../../../../helpers/alert.helpers';

import { reactValidatorOptions } from '../../../../../helpers/simpleReactValidator';
import { useWindowSize } from '../../../../../hooks/useResizeWindows';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.fourthBackgroundColor,
    color: theme.palette.primaryColor,
    fontWeight: 300,
    overflow: 'auto',
    borderRadius: '5px 5px 0px 0px',
    marginTop: 12,
    position: 'relative',
  },
  header: {
    color: theme.palette.fourthColor,
    fontWeight: 'bold',
    padding: '2%',
  },
  listDetails: {
    padding: '1%',
    '& p': {
      margin: '1% 0 ',
    },
  },
  content: {
    fontSize: 14,
    fontWeight: 300,
    display: 'flex',
    padding: 3,
  },
  icon: {
    color: '#2CC63E',
    marginLeft: 0,
    marginRight: 10,
    fontSize: 19,
  },
  iconYellow: {
    color: theme.palette.fourthColor,
    marginLeft: 0,
    marginRight: 10,
    fontSize: 19,
  },
  title: {
    fontWeight: 600,
    fontSize: 24,
  },
  label: {
    flexDirection: 'column',
    fontSize: 10,
    color: theme.palette.primaryColor,
  },
  formCheck: {
    margin: 0,
    '& .MuiIconButton-root': {
      padding: 0,
    },
  },
  styleActions: {
    backgroundColor: '#393939',
    bottom: '0',
    position: 'sticky',
    width: '100%',
  },
  cardContent: {
    paddingTop: 5,
    overflow: 'auto',
    padding: '2%',
  },
  divider: {
    width: '100%',
    borderBottom: '1px solid gray',
    display: 'inline-grid',
  },
  iconComun: {
    color: theme.palette.primaryColor,
  },
  containerProgress: {
    textAlign: 'center',
    width: '100%',
  },
  titleInfo: {
    color: theme.palette.secundaryColor,
    fontSize: 14,
    fontWeight: 600,
  },
  buttonGreen: {
    width: '100%',
    backgroundColor: theme.palette.secundaryColor,
    color: theme.palette.primaryBackgroundColor,
    outline: 'none',
    borderRadius: 5,
    boxShadow: 'none',
    border: 'none',
    fontWeight: 'bold',
    fontSize: 16,
    fontFamily: 'Muli',
    display: 'inline-flex',
  },
  wordBreak: {
    wordBreak: 'break-all',
  },
  redirectButton: {
    color: '#323232',
    cursor: 'pointer',
    backgroundColor: '#2CC63E',
    borderRadius: '5px',
    margin: '1% 0',
    padding: '1%',
    textAlign: 'center',
    fontWeight: '600',
    fontSize: '1.2em',
  },
  lastSupports: {
    color: '#2CC63E',
    cursor: 'pointer',
    backgroundColor: '#1212124D',
    borderRadius: '5px',
    margin: '1% 0',
    padding: '1%',
    textAlign: 'center',
    fontWeight: '600',
    fontSize: '1.2em',
  },
  rootsel: {
    backgroundColor: '#3B3B3B',
    color: '#FFFFFF',
    fontWeight: 300,
    fontFamily: 'Muli',
    cursor: 'pointer',
    fontSize: '16px',
    '& .MuiCardContent-root': {
      paddingTop: 0,
      paddingBottom: 5,
      marginBottom: 0,
      marginTop: 0,
      paddingLeft: 55,
    },
    '& .MuiCardHeader-root': {
      paddingBottom: 0,
      paddingTop: 5,
    },
  },
  buttonContinue: {
    width: '40%',
  },
}));

const Detail = ({
  employeeDetail,
  loadingSpinner,
  changeStateFavorite,
  changeEditingStatus,
  changeOpenRegisterEmployee,
  retireEmployee,
  handleReintegrate,
}) => {
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);
  const [branchName, setBranchName] = useState(null);
  const [state, setState] = useState(null);
  const [heightDetail, setHeightDetail] = useState(0);
  const [, forceUpdate] = useState();

  // react router
  let history = useHistory();

  // store
  const getListBranch = useSelector(
    (state) =>
      state.branchOfficeReducer.filteredBranchOfficesList?.data || null,
  );

  const listStates = useSelector(
    (state) => state.configReducer.getStatesResponse || null,
  );

  const { loadingRetireEmployee } = useSelector((s) => s.employeeReducer);

  //Custom hook
  const { contractData, setContractData, getRetireCauses, sendSupportToMail } =
    useRegisterEmployee();

  const { height } = useWindowSize();

  const { saveNavigation, fillDetailPayrollSupport } = usePayroll();

  // React validator
  const validator = useRef(
    new SimpleReactValidator(reactValidatorOptions),
  ).current; // DEBE SER CURRENT

  /**
   * Destacar empleaado
   * @param {object} e Evento
   * @param {object} item Información empleado a desctacar
   */
  const onChangeFavoriteEmployee = (e, item) => {
    e.preventDefault();
    changeStateFavorite(item.id, !item.favorito);
  };
  /**
   * Cambia estado
   * @param {object} e Evento
   * @param {object} item Información empleado a desctacar
   */
  const editEmployeeStatus = (e) => {
    e.preventDefault();
    changeEditingStatus(true);
    changeOpenRegisterEmployee(true);
  };

  /**
   * Sincroniza nuevo valor de Input con state
   * @param {*} e Evento
   */
  const syncChanges = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setContractData({
      ...contractData,
      [name]: value,
    });
  };
  /**
   * Sincroniza nuevo valor datePicker
   * @param {*} id Identificador
   * @param {*} date Nueva Fecha
   * @param {*} name Nombre datePicker
   */
  const handleDateFormPicket = (id, date, name) => {
    setContractData({
      ...contractData,
      [id]: new Date(date).toISOString(),
    });
  };

  /**
   * Abre modal, dilengenciar causal de retiro empleado
   */
  const openModalRetireEmployee = () => {
    setOpenModal(true);
  };

  /**
   * Cierra modal, dilengenciar causal de retiro empleado
   */
  const closeModalRetireEmployee = () => {
    setOpenModal(false);
  };

  /**
   * Valida formulario causal retiro
   * @param {*} e
   * @param {*} employeeDetail
   */
  const onSubmitModal = (e, employeeDetail) => {
    e.preventDefault();
    if (validator.allValid()) {
      closeModalRetireEmployee();
      ConfirmAlert2(
        `¿Esta seguro que desea retirar el empleado ${employeeDetail.primernombre} ${employeeDetail.primerapellido}?`,
        confirmRetireProcess,
        'Retirar',
      );
    } else {
      validator.showMessages();
      forceUpdate(Math.random());
    }
  };

  /**
   * Marca como retirado a un empleado
   */
  const confirmRetireProcess = () => {
    retireEmployee(
      employeeDetail.id,
      contractData.causalRetiroId,
      contractData.fechaRetiro,
      employeeDetail.primernombre,
      employeeDetail.primerapellido,
    );
  };

  const viewAllSupports = () => {
    saveNavigation({
      contentTab: 1,
      tabSelected: 1,
      isRedirecting: true,
    });

    fillDetailPayrollSupport({
      cargo: employeeDetail.cargo,
      documento: employeeDetail.documento,
      id: employeeDetail.id,
      otronombre: employeeDetail.otronombre,
      primerapellido: employeeDetail.primerapellido,
      primernombre: employeeDetail.primernombre,
      salario: employeeDetail.salario,
      segundoapellido: employeeDetail.segundoapellido || '',
      tipocontrato: employeeDetail.tipocontrato?.descripcion || '',
      tipodocumento: employeeDetail.tipodocumento,
    });

    history.push('/payroll');
  };

  /**
   * Evento, Reintegrar empleado
   */
  const confirmReintegrate = () => {
    ConfirmAlert2(
      `¿Esta seguro que desea reintegrar el empleado ${employeeDetail.primernombre} ${employeeDetail.primerapellido}?`,
      handleReintegrate,
      'Reintegrar',
    );
  };

  /**
   * Obtener fecha minima para fecha vencimiento
   */
  const getDateMinRetire = (dateCurrent) => {
    if (dateCurrent == null || dateCurrent === undefined) {
      return null;
    }

    let date = new Date(dateCurrent);
    return date;
  };

  // react hooks

  useEffect(() => {
    if (employeeDetail && getListBranch && getListBranch.length) {
      const nameBranch = getListBranch.find(
        (branch) => branch.id === employeeDetail.sucursalid,
      );
      setBranchName(nameBranch);
    }
  }, [employeeDetail, getListBranch]);

  useEffect(() => {
    if (listStates && listStates.length > 0 && employeeDetail) {
      const stateAux =
        listStates.find(
          (element) =>
            Number.parseInt(element.value) ===
            employeeDetail.ciudad?.departamentoid,
        ) || '';
      setState(stateAux);
    } else {
      setState('');
    }
  }, [listStates, employeeDetail]);

  //Lee altura de pantalla
  useEffect(() => {
    const windowsHeight = height ?? 0;
    const discount = windowsHeight * 0.191;
    setHeightDetail(windowsHeight - discount);
  }, [height]);

  /**
   * Envia soporte de nomina a un determinado corrreo electronico
   * @param {*} mailTo Correo electronico
   * @param {*} codigounico Codigo unico del soporte de nómina
   */
  const SendSupportToMail = (
    mailTo,
    codigounico,
    inactiveLoadingSendSupportPayroll,
  ) => {
    sendSupportToMail(
      mailTo,
      getFullName(
        employeeDetail.primerapellido,
        employeeDetail.segundoapellido,
        employeeDetail.primernombre,
        employeeDetail.otronombre,
      ),
      codigounico,
      inactiveLoadingSendSupportPayroll,
    );
  };

  //Components
  return (
    <>
      {!!employeeDetail ? (
        <>
          <Card
            className={classes.root}
            style={{ minHeight: heightDetail, maxHeight: heightDetail }}
          >
            <CardHeader
              className={classes.header}
              classes={{
                title: classes.title,
              }}
              title={getFullName(
                employeeDetail.primerapellido,
                employeeDetail.segundoapellido,
                employeeDetail.primernombre,
                employeeDetail.otronombre,
              )}
              subheader=""
            />
            <CardContent className={classes.cardContent}>
              <span className={classes.content} component="div">
                {documentDescription(
                  !!employeeDetail.diantipodocumentoidentidadid
                    ? employeeDetail.diantipodocumentoidentidadid
                    : 0,
                )}
                {employeeDetail.documento}
              </span>
              <span className={classes.content} component="div">
                <div className={classes.wordBreak}> {employeeDetail.cargo}</div>
              </span>
              <span className={classes.content} component="div">
                <div className={classes.wordBreak}>
                  {' '}
                  Contrato a {employeeDetail?.tipocontrato?.descripcion}
                </div>
              </span>
              <span className={classes.content} component="div">
                <div className={classes.wordBreak}>
                  Salario: $
                  {formatCurrencyColombia(employeeDetail.salario ?? 0)}
                </div>
              </span>
              <span className={classes.content} component="div">
                <CalendarIcon className={classes.icon} />
                <div className={classes.wordBreak}>
                  Fecha ingreso:{' '}
                  {employeeDetail?.fechaingreso !== null &&
                  employeeDetail?.fechaingreso !== undefined
                    ? formatDate(
                        new Date(employeeDetail.fechaingreso),
                        'dd/MM/yyyy',
                      )
                    : '-'}
                </div>
              </span>
              {((employeeDetail?.fecharetiro !== null &&
                employeeDetail?.fecharetiro !== undefined) ||
                (employeeDetail?.fechafinalizacioncontrato !== null &&
                  employeeDetail?.fechafinalizacioncontrato !== undefined)) && (
                <span className={classes.content} component="div">
                  <EventBusyIcon className={classes.iconYellow} />
                  <div className={classes.wordBreak}>
                    Fecha Retiro:{' '}
                    {formatDate(
                      new Date(
                        employeeDetail.fecharetiro ??
                          employeeDetail.fechafinalizacioncontrato,
                      ),
                      'dd/MM/yyyy',
                    )}
                  </div>
                </span>
              )}
              <span className={classes.content} component="div">
                <PhoneIcon className={classes.icon} />
                <div className={classes.wordBreak}>
                  {employeeDetail.telefono}
                </div>
              </span>
              <span className={classes.content} component="div">
                <RoomIcon className={classes.icon} />
                <div className={classes.wordBreak}>
                  {branchName ? branchName.nombre : ''}
                </div>
              </span>
              <span className={classes.content} component="div">
                <CalendarIcon
                  className={classes.icon}
                  style={{ visibility: 'hidden' }}
                />
                <div className={classes.wordBreak}>
                  {`${employeeDetail.ciudad?.descripcion} - ${
                    state?.text || ''
                  }`}
                </div>
              </span>
            </CardContent>

            {/* Acordeon Informacion adicional */}
            <CollapsePanel
              component={AditionalInformation}
              title={'Información Adicional'}
              titleOpen={'Información Adicional'}
              titleEdit={'Información Adicional'}
              backgroundColorSummary={{
                backgroundColor: '#2CC63E',
                color: '#393939',
                textAlign: 'center',
                maxHeight: '35px',
              }}
              backgroundColorDetail={{}}
              type="showinfo"
              data={employeeDetail}
            />

            <Grid container direction="column" className={classes.listDetails}>
              {/* Seccion con Ultimos desprendibles de nomina */}
              <div
                className={classes.lastSupports}
                style={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                  color: 'rgb(44 198 62)',
                }}
              >
                Últimos soportes de nómina
              </div>
              {employeeDetail && !employeeDetail.lastSupports && (
                <>
                  <p
                    style={{
                      color: '#FFFFFF80',
                      margin: '0 auto',
                      minHeight: '20vh',
                    }}
                  >
                    Aún no has reportado soportes de nómina de este empleado
                  </p>
                  <div style={{ minHeight: '100px' }}></div>
                </>
              )}
              {employeeDetail && employeeDetail.lastSupports
                ? employeeDetail.lastSupports.map((support) => (
                    <PaymentsDetail
                      support={support}
                      sendSupportToMail={SendSupportToMail}
                    />
                  ))
                : ''}
              {/* Acordeon Ver todos los desprendibles */}
              {employeeDetail && employeeDetail.lastSupports && (
                <div
                  className={classes.redirectButton}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                  onClick={viewAllSupports}
                >
                  Ver todos los soportes de nómina de este empleado
                  <IconButton
                    classes={{
                      root: classes.button,
                      label: classes.label,
                    }}
                    variant="raised"
                    disableRipple={true}
                    onClick={openModalRetireEmployee}
                  >
                    <img
                      src={arrowRight}
                      alt="Logo"
                      style={{ maxWidth: '48px' }}
                      className={classes.iconAction}
                    />
                  </IconButton>
                </div>
              )}
            </Grid>
            {/* Botones: Retirar empleado, destacar y editar */}
            <CardActions className={classes.styleActions}>
              <Grid
                container
                style={{ justifyContent: 'space-evenly', alignItems: 'center' }}
              >
                {employeeDetail.retirado === 0 && (
                  <Grid item lg={4} xs={4} style={{ textAlign: 'center' }}>
                    {loadingRetireEmployee ? (
                      <div style={{ paddingTop: 20 }}>
                        <CustomProgress size={20} />
                      </div>
                    ) : (
                      <IconButton
                        classes={{
                          root: classes.button,
                          label: classes.label,
                        }}
                        variant="raised"
                        disableRipple={true}
                        onClick={openModalRetireEmployee}
                      >
                        <img
                          src={retireEmployeeIcon}
                          alt="Logo"
                          style={{ maxWidth: '48px' }}
                          className={classes.iconAction}
                        />
                        {'Retirar empleado'}
                      </IconButton>
                    )}
                  </Grid>
                )}

                {employeeDetail.retirado === 1 &&
                  employeeDetail?.fecharetiro && (
                    <Grid item lg={4} xs={4} style={{ textAlign: 'center' }}>
                      {false === true ? (
                        <div style={{ paddingTop: 20 }}>
                          <CustomProgress size={20} />
                        </div>
                      ) : (
                        <IconButton
                          classes={{
                            root: classes.button,
                            label: classes.label,
                          }}
                          variant="raised"
                          disableRipple={true}
                          onClick={confirmReintegrate}
                        >
                          <PersonAddIcon className={classes.iconAction} />
                          {'Reintegrar empleado'}
                        </IconButton>
                      )}
                    </Grid>
                  )}

                {employeeDetail.retirado === 0 && (
                  <Grid item lg={4} xs={4} style={{ textAlign: 'center' }}>
                    {loadingSpinner ? (
                      <div style={{ paddingTop: 20 }}>
                        <CustomProgress size={20} />
                      </div>
                    ) : (
                      <FormControlLabel
                        style={{ margin: 0, height: '75%' }}
                        control={
                          <Checkbox
                            name="checkedF"
                            checked={employeeDetail.favorito}
                            onChange={(e) => {
                              onChangeFavoriteEmployee(e, employeeDetail);
                            }}
                            className={classes.formCheck}
                            icon={
                              <IconButton
                                classes={{
                                  root: classes.button,
                                  label: classes.label,
                                }}
                                variant="raised"
                                disableRipple={true}
                              >
                                <StarBorderIcon
                                  className={classes.iconAction}
                                />
                                {'Destacar'}
                              </IconButton>
                            }
                            checkedIcon={
                              <IconButton
                                classes={{
                                  root: classes.button,
                                  label: classes.label,
                                }}
                                variant="raised"
                                disableRipple={true}
                              >
                                <StarIcon className={classes.iconAction} />
                                {'Destacado'}
                              </IconButton>
                            }
                          />
                        }
                      />
                    )}
                  </Grid>
                )}
                <Grid item lg={4} xs={4} style={{ textAlign: 'center' }}>
                  <IconButton
                    classes={{ root: classes.button, label: classes.label }}
                    variant="raised"
                    disableRipple={true}
                    onClick={editEmployeeStatus}
                  >
                    <EditIcon className={classes.iconAction} />
                    {'Editar'}
                  </IconButton>
                </Grid>
              </Grid>
            </CardActions>
          </Card>

          {/* Modal Retiro empleado */}
          <SimpleModal
            onClose={closeModalRetireEmployee}
            open={openModal}
            title="Retirar Empleado"
            component={() => {
              return (
                <Grid container>
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm
                    style={{
                      padding: '3%',
                      fontSize: '17px',
                    }}
                  >
                    Por favor ingresa la información de retiro
                  </Grid>
                  <Grid item lg={6} md={6} sm>
                    <DatePickerForm
                      label={'Fecha de retiro'}
                      value={contractData.fechaRetiro}
                      id={'fechaRetiro'}
                      name={'fechaRetiro'}
                      minDate={getDateMinRetire(employeeDetail?.fechaingreso)}
                      onChange={handleDateFormPicket}
                      validator={validator}
                      validateOptions={'required'}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm>
                    <SelectForm
                      label={'Causal de retiro *'}
                      name="causalRetiroId"
                      value={contractData.causalRetiroId}
                      options={getRetireCauses}
                      validator={validator}
                      validateOptions={'required'}
                      onChange={(e) => syncChanges(e)}
                    />
                  </Grid>
                  <Grid item lg={4} md={4} sm style={{ margin: '0 auto' }}>
                    <ButtonPrimary
                      text={'Continuar'}
                      onClick={(e) => onSubmitModal(e, employeeDetail)}
                      type={'button'}
                      style={{ color: '#FFF' }}
                    />
                  </Grid>
                </Grid>
              );
            }}
          />
        </>
      ) : (
        'No existe información'
      )}
    </>
  );
};

export default Detail;
