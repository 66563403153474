import React from 'react';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  IconButton,
  makeStyles,
  Tooltip,
} from '@material-ui/core';
import ReportIcon from '@material-ui/icons/Report';

import CustomProgress from '../../Progress/progress.component';
import { reportErrorDocument } from '../helpers/reportErrorDocument';
import { TooltipContent } from './TooltipErrorMessage';

const useStyles = makeStyles((theme) => ({
  iconButton: {
    color: 'white',
    padding: 0,
  },
  tooltip: {
    backgroundColor: 'transparent',
    '& .MuiTooltip-tooltip': {
      backgroundColor: 'blue',
    },
  },
}));

const ErrorStatus = ({ item, messageTooltip }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { data: issue } = useSelector((state) => state.configReducer);
  const { loadingRejectReport } = useSelector(
    (state) => state.payrollReportReducer,
  );

  const { detallenominaid, reporteRechazo, estadonominaid } = item;
  const emailSupport = issue?.find((c) => c.id === 10)?.correo;
  const isError = estadonominaid === 3;
  const isLoading = loadingRejectReport.includes(detallenominaid);

  return (
    <Tooltip
      className={classes.tooltip}
      disableHoverListener={isLoading}
      disableTouchListener={isLoading}
      enterTouchDelay={1000}
      interactive
      placement="bottom-end"
      title={
        <TooltipContent
          isError={isError}
          reporteRechazo={reporteRechazo}
          messageTooltip={messageTooltip}
          reportErrorDocument={(e) =>
            reportErrorDocument(e, detallenominaid, emailSupport, dispatch)
          }
        />
      }
    >
      {isLoading ? (
        <CustomProgress size={20} aling="end" margin="0 0 0 10px" />
      ) : (
        <IconButton className={classes.iconButton}>
          <ReportIcon />
        </IconButton>
      )}
    </Tooltip>
  );
};

export default ErrorStatus;
