/**
 * Retorna la descripción del rol conforme al codigo en el token.
 * @param {string} code Código del Rol(CNADMIN, CNCOLAB, CNREPLEG).
 * @returns {string} Descripción del Rol (Administrador, Colaborador, Representante Legal).
 * @example
 */
export const getRoleDescription = (code) => {
  switch (code) {
    case 'CNADMIN':
      return 'Administrador';
    case 'CNCOLAB':
      return 'Colaborador';
    case 'CNREPLEG':
      return 'Representante Legal';
    default:
      return '';
  }
};
