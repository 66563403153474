import {
  Button,
  withStyles,
} from '@material-ui/core';

export const CustomButton = withStyles((theme) => ({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    fontWeight: 600,
    padding: '2px 20px',
    border: '1px solid',
    lineHeight: 1.5,
    backgroundColor: '#C7C7C7',
    borderColor: '#C7C7C7',
    borderRadius: '35px',
    fontSize: 12,
    color: 'black',
    cursor: 'point',
    '&:active': {
      boxShadow: 'none',
      backgroundColor: '#C7C7C7',
    },
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: '#C7C7C7',
    },
    '&:focus': {
      boxShadow: 'none',
      backgroundColor: '#C7C7C7',
    },
  },
}))(Button);
