import React from 'react';

import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  makeStyles,
} from '@material-ui/core';

import { CustomButton } from './CustomButton';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#FFFF',
    color: 'black',
    textAlign: 'center',
  },
  action: {
    flexDirection: 'column',
    justifyContent: 'center',
  },
  title: {
    fontWeight: 800,
    fontSize: 14,
  },
  message: {
    fontSize: 12,
    whiteSpace: 'pre-wrap',
  },
  content: {
    padding: '5px 16px',
  },
  header: {
    padding: '3px 16px 0px 16px',
    marginTop: '10px',
  },
}));

export const TooltipMessageSecundary = React.forwardRef(
  ({ message, title, buttonText, onClick, disabledButton }, ref) => {
    const classes = useStyles();
    return (
      <Card className={classes.root} ref={ref}>
        <CardHeader
          className={classes.header}
          title={title}
          classes={{
            title: classes.title,
          }}
        />
        <CardContent className={classes.content}>
          <div className={classes.message}>{message}</div>
        </CardContent>
        {disabledButton !== true ? (
          <CardActions className={classes.action}>
            <CustomButton onClick={onClick} disabled={disabledButton}>
              {buttonText}
            </CustomButton>
          </CardActions>
        ) : null}
      </Card>
    );
  },
);
