import { types } from '../actions/types';

const initialState = {
  nominaid: 0,
  fechapago: null,
  totalconfirmados: 0,
  totalempleados: 0,
  totalnomina: 0,
  totaltransmitidos: 0,
  payrollListReport: [],
  payrollListChecked: [],
  payrollPreview: null,
  activeSuport: {
    detallenominaid: null,
    nombrecompleto: null,
    tipodocumento: null,
    documento: null,
    cargo: null,
    tipocontrato: null,
    telefono: null,
    direccion: null,
    ciudad: null,
    departamento: null,
    valornomina: null,
    estadonominaid: null,
    observacion: null,
  },
  filterData: {
    Mes: null,
    Anio: null,
    Confirmado: false,
    NoConfirmado: false,
    Transmitido: false,
    Retirado: false,
    Favorito: false,
    Filtro: null,
    TipoOrdenamiento: 1,
    Limit: 900,
    Offset: 0,
    LoadMore: false,
    CargueMasivo: false,
    CargueMasivoSelected: false, // ! Solo se utiliza para cargue masivo
  },
  loadingList: false,
  loadingDetail: false,
  loadingConfirm: false,
  loadingTransmission: false,
  loadingPayDate: false,
  loadingTotal: false,
  loadingObservation: false,
  loadingPayrollPreview: false,
  statusTransmission: null,
  startPayrollTransmission: false,
  loadingRejectReport: [],
};

//Reducer
export const payrollReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FILL_PAYROLL_REPORT:
      return {
        ...state,
        payrollListReport: action.payload,
      };
    case types.CHECK_ALL_PAYROLL: {
      if (action.payload) {
        let newList = state.payrollListReport.filter(
          (item) => Number(item.estadonominaid) === 1,
        );
        return {
          ...state,
          payrollListChecked: [...newList],
          payrollListReport: state.payrollListReport.map((item) =>
            Number(item.estadonominaid) === 1
              ? { ...item, seleccionado: true }
              : { ...item, seleccionado: false },
          ),
        };
      } else {
        return {
          ...state,
          payrollListChecked: [],
          payrollListReport: state.payrollListReport.map((item) => ({
            ...item,
            seleccionado: false,
          })),
        };
      }
    }
    case types.SET_FILTER_PAYROLL:
      return {
        ...state,
        filterData: { ...action.payload },
      };
    case types.LOADING_PAYROLL_REPORT:
      return {
        ...state,
        loadingList: action.payload,
      };
    case types.FILL_SUPPORT_EMPLOYEE: {
      return {
        ...state,
        activeSuport: { ...action.payload },
        payrollListReport: state.payrollListReport.map((item) =>
          item.empleadoid === action?.payload?.empleadoid
            ? { ...item, detallenominaid: action.payload.detallenominaid }
            : { ...item },
        ),
      };
    }
    case types.LOADING_GET_SUPPORT:
      return {
        ...state,
        loadingDetail: action.payload,
      };
    case types.ADD_CHECKED_PAYROLL: {
      const { empleadoid, checked } = action.payload;
      const empleado = state.payrollListReport.find(
        (c) => c.empleadoid === empleadoid,
      );
      const current = state.payrollListChecked.find(
        (item) => item.empleadoid === empleadoid,
      );

      let newChecked = [...state.payrollListChecked];
      if (!current && empleado) {
        newChecked.push(empleado);
      } else {
        newChecked = newChecked.filter(
          (item) => item.empleadoid !== empleadoid,
        );
      }
      return {
        ...state,
        payrollListChecked: newChecked,
        payrollListReport: state.payrollListReport.map((item) =>
          Number(item.empleadoid) === Number(empleadoid)
            ? { ...item, seleccionado: checked }
            : item,
        ),
      };
    }
    case types.CONFIRM_PAYROLL: {
      let newChecked = [...state.payrollListChecked];
      const estado = Boolean(action.payload.estado);
      const detallenominaid = Number(action.payload.detallenominaid);

      if (!estado) {
        newChecked = state.payrollListChecked.filter(
          (item) => item.detallenominaid !== detallenominaid,
        );
      }
      return {
        ...state,
        payrollListReport: state.payrollListReport.map((item) =>
          item.detallenominaid === detallenominaid
            ? {
                ...item,
                estadonominaid: estado ? 1 : 0,
                seleccionado: false,
              }
            : { ...item },
        ),
        activeSuport:
          detallenominaid === state.activeSuport.detallenominaid
            ? {
                ...state.activeSuport,
                estadonominaid: Boolean(estado) ? 1 : 0,
              }
            : state.activeSuport,
        payrollListChecked: newChecked,
      };
    }
    case types.LOADING_CONFIRM_PAYROLL:
      return {
        ...state,
        loadingConfirm: action.payload,
      };
    case types.UPDATE_TOTAL_PAYROLL: {
      const detallenominaid = action.payload.detallenominaid;
      return {
        ...state,
        activeSuport:
          state.activeSuport.detallenominaid === detallenominaid
            ? {
                ...state.activeSuport,
                valornomina: action.payload.valornomina,
              }
            : state.activeSuport,
      };
    }

    case types.SET_DATE_INSTANCE:
      return {
        ...state,
        filterData: {
          ...state.filterData,
          Mes: action.payload.month,
          Anio: action.payload.year,
        },
      };
    case types.START_TRANSMISSION: {
      let newList = [...state.payrollListReport];
      const { Mes } = { ...state.filterData };

      const index = newList.findIndex(
        (item) => item.detallenominaid === action.payload.detalleId,
      );

      if (index !== -1) {
        newList[index].estadonominaid = action.payload.estadonominaId;
      } else {
        const nullDetailIndex = newList.findIndex(
          (item) =>
            item.empleadoid === action.payload.empleadoId &&
            item.detallenominaid === null &&
            Mes === action.payload.mesNomina,
        );

        if (nullDetailIndex !== -1) {
          newList[nullDetailIndex] = {
            ...newList[nullDetailIndex],
            detallenominaid: action.payload.detalleId,
            estadonominaid: action.payload.estadonominaId,
          };
        }
      }

      return {
        ...state,
        payrollListReport: newList,
      };
    }
    case types.LOADING_TRANSMISSION:
      return {
        ...state,
        loadingTransmission: action.payload,
      };
    case types.START_PAYROLL_TRANSMISSION:
      return {
        ...state,
        startPayrollTransmission: action.payload.inicioTransmision,
      };
    case types.UPDATE_STATUS_TRANSMISSION: {
      const empleadoid = action.payload.empleadoid;
      const estadonominaid = action.payload.estadonominaid;
      const fechavalidacion = action.payload.fechaValidacion;

      return {
        ...state,
        payrollListReport: state.payrollListReport.map((item) =>
          item.empleadoid === empleadoid
            ? {
                ...item,
                estadonominaid: estadonominaid,
                seleccionado: false,
                fechavalidaciondian: fechavalidacion,
              }
            : item,
        ),
        activeSuport: {
          ...state.activeSuport,
          estadonominaid:
            state.activeSuport.empleadoid === empleadoid
              ? estadonominaid
              : state.activeSuport.estadonominaid,
        },
        payrollListChecked: state.payrollListChecked.filter(
          (c) => c.empleadoid !== empleadoid,
        ),
        statusTransmission: action.payload,
      };
    }
    case types.UPDATE_CHECKED_LIST:
      return {
        ...state,
        payrollListChecked: action.payload,
      };
    case types.LOADING_PAY_DATE:
      return {
        ...state,
        loadingPayDate: action.payload,
      };
    case types.GET_TOTAL_DATA_PAYROLL:
      return {
        ...state,
        totalconfirmados: action.payload.totalconfirmados ?? 0,
        totalempleados: action.payload.totalempleados ?? 0,
        totalnomina: action.payload.totalnomina ?? 0,
        totaltransmitidos: action.payload.totaltransmitidos ?? 0,
      };
    case types.LOADING_TOTAL_PAYROLL:
      return {
        ...state,
        loadingTotal: action.payload,
      };
    case types.SET_DATA_PAYROLL:
      return {
        ...state,
        nominaid: action.payload?.nominaid ?? 0,
        fechapago: action.payload?.fechapago ?? 0,
      };
    case types.UPDATE_DATA_TRANSMISSION:
      return {
        ...state,
        statusTransmission: null,
      };
    case types.SET_CHECKED_PAYROLL:
      return {
        ...state,
        payrollListChecked: action.payload,
      };
    case types.CLEAN_PAYROLL_REPORT:
      return initialState;

    case types.ADD_OBSERVATION_PAYROLL:
      return {
        ...state,
        activeSuport: {
          ...state.activeSuport,
          observacion: action.payload,
        },
      };
    case types.LOADING_OBSERVATION:
      return {
        ...state,
        loadingObservation: action.payload,
      };

    case types.GET_PAYROLL_PREVIEW:
      return {
        ...state,
        payrollPreview: action.payload,
      };
    case types.LOADING_PAYROLL_PREVIEW:
      return {
        ...state,
        loadingPayrollPreview: action.payload,
      };
    case types.CLEAN_PAYROLL_PREVIEW:
      return {
        ...state,
        payrollPreview: action.payload,
      };
    case types.UPDATE_REJECT_REPORT:
      return {
        ...state,
        payrollListReport: state.payrollListReport.map((item) =>
          item.detallenominaid === action.payload.detalleNominaId
            ? { ...item, reporteRechazo: true }
            : item,
        ),
      };
    case types.LOADING_REJECT_REPORT:
      return {
        ...state,
        loadingRejectReport: action.payload.loading
          ? [...state.loadingRejectReport, action.payload.id]
          : (state.loadingRejectReport || []).filter(
              (id) => id !== action.payload.id,
            ),
      };
    default:
      return state;
  }
};
