import React from 'react';

import { TooltipMessageSecundary } from './TooltipMessageSecundary';

export const TooltipContent = ({
  isError,
  reporteRechazo,
  reportErrorDocument,
}) => {
  return !isError ? (
    <TooltipMessageSecundary
      buttonText="Reportar a soporte"
      disabledButton={reporteRechazo === true}
      message={
        reporteRechazo !== true
          ? 'El documento presenta una inconsistencia ante la DIAN, has clic en el siguiente botón para reportarlo a nuestro equipo de soporte y poder ayudarte.'
          : 'Este caso ya fue reportado, área de soporte está trabajando para brindarte una solución oportuna.'
      }
      onClick={reportErrorDocument}
      title={`Error DIAN - Documento con errores`}
    />
  ) : (
    <TooltipMessageSecundary
      buttonText="Reportar a soporte"
      disabledButton={reporteRechazo === true}
      message={
        reporteRechazo !== true
          ? 'El documento de respuesta de DIAN contiene errores, por favor repórtalo a nuestro equipo de soporte para poder ayudarte.'
          : 'Este caso ya fue reportado, área de soporte está trabajando para brindarte una solución oportuna.'
      }
      onClick={reportErrorDocument}
      title={`Error DIAN - Documento de Respuesta de DIAN con Error `}
    />
  );
};
