import {
  businessName,
  emailUser,
  fullNameUser,
  idUser,
  nitCompany,
} from '../../../actions/authActions';
import {
  sendMailPayrrollReportAction,
} from '../../../actions/payrollReportAction';
import { getRoleDescription } from '../../../helpers/userRolDescription';

export const reportErrorDocument = (
  e,
  detalleNominaId,
  emailSupport,
  dispatch,
) => {
  e.preventDefault();

  let data = {
    bodyMail: 'Nomina Electrónica rechazada ante DIAN.',
    detalleNominaId: detalleNominaId,
    issue: 'Nomina Electrónica rechazada ante DIAN.',
    mailSupport: emailSupport,
    mailUser: emailUser(),
    nameCompany: businessName(),
    nitCompany: nitCompany(),
    roleUser: getRoleDescription(idUser()),
    userName: fullNameUser(),
  };

  dispatch(sendMailPayrrollReportAction(data));
};
